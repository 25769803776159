import React from 'react';
import { Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './Dashboard.scss';
import useOrders from '../../firebase files/hooks/useOrders';
import { useAuthContext } from '../../firebase files/hooks/useAuthContext';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const mockData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  datasets: [
    {
      label: 'Monthly Spend',
      data: [4000, 3000, 5000, 2780, 1890, 2390],
      fill: false,
      borderColor: '#8884d8',
      tension: 0.1,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        boxWidth: 12,
        padding: 15,
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: 'Monthly Spending',
      font: {
        size: 14
      }
    },
  },
};

const pieData = {
  labels: ['Drycleaning', 'Laundry', 'Ironing', 'Wash & Fold', 'Wash & Dry'],
  datasets: [
    {
      data: [35, 25, 15, 15, 10],
      backgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#4BC0C0',
        '#9966FF'
      ],
      borderColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#4BC0C0',
        '#9966FF'
      ],
      borderWidth: 1,
    },
  ],
};

const pieOptions = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 12,
        padding: 15,
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: 'Orders by Category',
      font: {
        size: 14
      }
    },
  },
};

const mockOrders = [
  {
    id: "ORD-2024-001",
    dateTime: "2024-03-15 14:30",
    total: 299.99,
    items: 3,
    status: "Delivered"
  },
  {
    id: "ORD-2024-002",
    dateTime: "2024-03-14 09:15",
    total: 150.50,
    items: 2,
    status: "In Transit"
  },
  {
    id: "ORD-2024-003",
    dateTime: "2024-03-13 16:45",
    total: 499.99,
    items: 5,
    status: "Processing"
  },
  {
    id: "ORD-2024-004",
    dateTime: "2024-03-12 11:20",
    total: 89.99,
    items: 1,
    status: "Delivered"
  },
  {
    id: "ORD-2024-005",
    dateTime: "2024-03-11 13:10",
    total: 199.99,
    items: 2,
    status: "Cancelled"
  }
];

const Dashboard = () => {
  const { orders } = useOrders();
  const { user } = useAuthContext();

  // Process orders data for statistics
  const calculateStats = () => {
    const totalOrders = orders.length;
    const totalSpent = orders.reduce((sum, order) => sum + (order.totalPrice || 0), 0);
    const pendingOrders = orders.filter(order => order.orderStatus === 'pending').length;
    const completedOrders = orders.filter(order => 
      order.currentOrderStage === 'delivered' || 
      order.orderStatus === 'completed'
    ).length;

    // Calculate percentage changes (mock for now, you can implement actual logic)
    return {
      totalOrders,
      totalSpent,
      pendingOrders,
      completedOrders,
      // You can calculate these based on previous month's data if available
      ordersChange: '+12.5%',
      spentChange: '+8.2%',
      pendingChange: '+2',
      completedChange: '0'
    };
  };

  // Process orders data for line chart
  const prepareMonthlyData = () => {
    const last6Months = new Array(6).fill(0);
    const labels = [];
    const currentDate = new Date();
    
    for (let i = 5; i >= 0; i--) {
      const month = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      labels.unshift(month.toLocaleString('default', { month: 'short' }));
    }

    orders.forEach(order => {
      if (order.createdAt) {
        const orderDate = order.createdAt.toDate();
        const monthDiff = (currentDate.getMonth() + 12 - orderDate.getMonth()) % 12;
        if (monthDiff < 6) {
          last6Months[5 - monthDiff] += order.totalPrice || 0;
        }
      }
    });

    return {
      labels,
      datasets: [{
        label: 'Monthly Spend',
        data: last6Months,
        fill: false,
        borderColor: '#8884d8',
        tension: 0.1,
      }],
    };
  };

  // Process orders data for pie chart
  const prepareCategoryData = () => {
    const categories = {};
    
    orders.forEach(order => {
      order.products?.forEach(product => {
        const category = product.category || 'Other';
        categories[category] = (categories[category] || 0) + 1;
      });
    });

    const labels = Object.keys(categories);
    const data = Object.values(categories);
    const colors = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
      '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'
    ];

    return {
      labels,
      datasets: [{
        data,
        backgroundColor: colors.slice(0, labels.length),
        borderColor: colors.slice(0, labels.length),
        borderWidth: 1,
      }],
    };
  };

  const stats = calculateStats();
  const monthlyData = prepareMonthlyData();
  const categoryData = prepareCategoryData();

  // Get recent orders
  const recentOrders = [...orders]
    .sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate())
    .slice(0, 5)
    .map(order => ({
      id: order.id,
      dateTime: order.createdAt?.toDate().toLocaleString(),
      total: order.totalPrice || 0,
      items: order.products?.length || 0,
      status: order.orderStatus
    }));

  return (
    <div className="customer-dashboard">
      <div className="customer-dashboard-sidebar">
        <div className="sidebar-header">
          <h2>Menu</h2>
        </div>
        <nav className="sidebar-nav">
          <ul>
            <li className="active">
              <span className="material-symbols-outlined">dashboard</span>
              Dashboard
            </li>
            <li>
              <span className="material-symbols-outlined">shopping_cart</span>
              Orders
            </li>
            <li>
              <span className="material-symbols-outlined">person</span>
              Profile
            </li>
          </ul>
        </nav>
      </div>

      <div className="customer-dashboard-content">
        <div className="dashboard-header">
          <h1>Welcome Back, {user && user.fullName}</h1>
          <p className="text-muted">Here's what's happening with your orders</p>
        </div>

        <div className="stats-grid">
          <div className="stat-card">
            <div className="stat-icon">
              <span className="material-symbols-outlined">shopping_cart</span>
            </div>
            <div className="stat-details">
              <p className="stat-label">Total Orders</p>
              <h2 className="stat-value">{stats.totalOrders}</h2>
              <p className="stat-change positive">{stats.ordersChange} from last month</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon">
              <span className="material-symbols-outlined">payments</span>
            </div>
            <div className="stat-details">
              <p className="stat-label">Total Spent</p>
              <h2 className="stat-value">₹{stats.totalSpent.toFixed(2)}</h2>
              <p className="stat-change positive">{stats.spentChange} from last month</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon">
              <span className="material-symbols-outlined">pending_actions</span>
            </div>
            <div className="stat-details">
              <p className="stat-label">Pending Orders</p>
              <h2 className="stat-value">{stats.pendingOrders}</h2>
              <p className="stat-change positive">{stats.pendingChange} from last week</p>
            </div>
          </div>

          <div className="stat-card">
            <div className="stat-icon">
              <span className="material-symbols-outlined">local_shipping</span>
            </div>
            <div className="stat-details">
              <p className="stat-label">Completed Orders</p>
              <h2 className="stat-value">{stats.completedOrders}</h2>
              <p className="stat-change neutral">{stats.completedChange}</p>
            </div>
          </div>
        </div>

        <div className="dashboard-charts">
          <div className="chart-wrapper spending-chart">
            <div className="chart-header">
              <h2>Spending Analytics</h2>
              <select className="time-filter">
                <option>Last 6 months</option>
                <option>Last 3 months</option>
                <option>Last month</option>
              </select>
            </div>
            <Line data={monthlyData} options={options} />
          </div>

          <div className="chart-wrapper category-chart">
            <div className="chart-header">
              <h2>Service Distribution</h2>
              <select className="time-filter">
                <option>All time</option>
                <option>This year</option>
                <option>This month</option>
              </select>
            </div>
            <Pie data={categoryData} options={pieOptions} />
          </div>
        </div>

        <div className="customer-dashboard-current-order-info">
          <div className="customer-dashboard-current-order-info-header">
            <h2>Your Live Orders</h2>

            <div className="customer-dashboard-current-order-info-view-all-btn-container">
              <button className="customer-dashboard-current-order-info-view-all-btn">
                View All Orders
                <span className="material-symbols-outlined">arrow_forward</span>
              </button>
            </div>
          </div>

          <div className="customer-dashboard-current-order-info-metrics-container">
            <div className="customer-dashboard-current-order-info-metrics">
              <div className="metric-card">
                <div className="metric-icon">
                  <span className="material-symbols-outlined">shopping_cart</span>
                </div>
                <div className="metric-details">
                  <p className="metric-label">Ongoing Orders</p>
                  <h2 className="metric-value">2</h2>
                </div>
              </div>
            </div>

            <div className="customer-dashboard-current-order-info-orders-container">
              <div className="customer-dashboard-current-order-info-order-card">
                <div className="customer-dashboard-current-order-info-order-card-header">
                  <h2>Order ID: #123456</h2>
                  <p className="text-muted">Order Placed: 2024-03-15 14:30</p>
                </div>
                <div className="customer-dashboard-current-order-info-order-card-body">
                  <p className="text-muted">Total: ₹299.99</p>
                  <p className="text-muted">Status: In Transit</p>
                </div>
              </div>

              <div className="customer-dashboard-current-order-info-order-card">
                <div className="customer-dashboard-current-order-info-order-card-header">
                  <h2>Order ID: #123456</h2>
                  <p className="text-muted">Order Placed: 2024-03-15 14:30</p>
                </div>
                <div className="customer-dashboard-current-order-info-order-card-body">
                  <p className="text-muted">Total: ₹299.99</p>
                  <p className="text-muted">Status: In Transit</p>
                </div>
              </div>
            </div>

            {/* <div className="customer-dashboard-current-order-info-view-all-btn-container">
              <button className="customer-dashboard-current-order-info-view-all-btn">
                View All Orders
                <span className="material-symbols-outlined">arrow_forward</span>
              </button>
            </div> */}
            
          </div>


        </div>

        <div className="recent-orders">
          <div className="section-header">
            <h2>Recent Orders</h2>
            <button className="view-all-btn">
              View All Orders
              <span className="material-symbols-outlined">arrow_forward</span>
            </button>
          </div>
          <div className="orders-table-wrapper">
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Date & Time</th>
                  <th>Total</th>
                  <th>Items</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                recentOrders.length > 0 ?
                recentOrders.map((order) => (
                  <tr key={order.id}>
                    <td className="order-id">{order.id}</td>
                    <td>{order.dateTime}</td>
                    <td className="order-total">₹{order.total.toFixed(2)}</td>
                    <td>{order.items}</td>
                    <td>
                      <span className={`status-pill ${order.status.toLowerCase()}`}>
                        {order.status}
                      </span>
                    </td>
                    <td>
                      <button className="action-btn">
                        <span className="material-symbols-outlined">visibility</span>
                        View Details
                      </button>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan="6" className="no-orders-message">No orders found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;