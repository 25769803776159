import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Cart.scss";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useDocument } from "../firebase files/hooks/useDocument";
import useCart from "../firebase files/hooks/useCart";
import Skeleton from "react-loading-skeleton";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import Popup from "./Popup";
import useOrders from "../firebase files/hooks/useOrders";
import { Link } from "react-router-dom";
import { useRazorpay } from "../firebase files/hooks/useRezorpay";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomSnackbar from './CustomSnackbar';
import OnlinePayment from "./OnlinePayment";

const Cart = () => {
  const navigate = useNavigate();
  const { documents: cartDocuments, error: cartErrors } = useCollection("cart");
  const { documents: productDocuments, error: productErrors } =
    useCollection("products");
  const { user } = useAuthContext();
  const { cartItems, updateCartItemQuantity, removeFromCart } = useCart();
  const { createOrder } = useOrders();
  const { document: currencyDocument, error: currencyError } = useDocument(
    "settings",
    "currency"
  );

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [tempSelectedAddress, setTempSelectedAddress] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);
  // const { makePayment, paymentResponse } = useRazorpay();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'error'
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cod");

  // useEffect(() => {
  //   // console.log(paymentResponse)
  //   let totalPayment = Number(1);
  //   let status = '';
  //   // console.log('paymentResponse : ', paymentResponse);
  //   if (paymentResponse) {
  //     // console.log('paymentResponse.paymentStatus.toUpperCase()', paymentResponse.paymentStatus.toUpperCase())
  //     status = paymentResponse.paymentStatus.toUpperCase() === 'SUCCESS' ? 'Completed' : 'Pending';

  //   }

  // }, [paymentResponse])


  // async function RazorPayPayment() {
  //   let orderId = "03948029138";
  //   let totalPayment = Number(1);
  //   console.log('orderId', orderId)

  //   makePayment(orderId,
  //     // Number(totalPayment),
  //     1,
  //     user.userName,
  //     user.email,
  //     user.phoneNumber

  //   ).then(async (e) => {
  //     console.log(e, 'paymentResponse', paymentResponse);

  //   })
  // }



  useEffect(() => {
    if (user?.addresses?.length > 0) {
      const defaultAddr =
        user.addresses.find((address) => address.isDefault) ||
        user.addresses[0];
      setSelectedAddress(defaultAddr);
      setTempSelectedAddress(defaultAddr);
    }
  }, [user]);

  useEffect(() => {
    if (currencyDocument && productDocuments && user) {
      setIsLoading(false);
    }
  }, [currencyDocument, productDocuments, user]);

  useEffect(() => {
    const currentError = productErrors || currencyError || cartErrors;
    if (currentError) setError(currentError);
  }, [productErrors, currencyError, cartErrors]);

  const handleAddressModal = () => {
    setIsAddressModalOpen(true);
  };

  const handleAddressSelection = (address) => {
    setTempSelectedAddress(address);
  };

  const handleSaveAddress = () => {
    setSelectedAddress(tempSelectedAddress);
    setIsAddressModalOpen(false);
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
  };

  const getProduct = (productId) => {
    if (!productDocuments) return null;
    return productDocuments.find((product) => product.id === productId);
  };

  const currentProductsInfo = (cartItems) => {
    if (!cartItems || !Array.isArray(cartItems)) return [];

    return cartItems.reduce((acc, item) => {
      const product = getProduct(item.productId);
      if (!product) return acc;

      return [
        ...acc,
        {
          id: product.id,
          quantity: item.quantity,
          price: product.price,
          discountedPrice: item.discountedPrice || "",
          name: product.name,
          images: product.images || [],
          color: item.color || "",
          size: item.size || "",
          variantName: item.variantName || "",
          category:item.category || ""
        },
      ];
    }, []);
  };

  const handleRemoveFromCart = (cartItemId) => {
    setItemToDelete(cartItemId);
    setPopupMessage("Do you want to remove this item from cart?");
    setShowPopup(true);
  };

  const handlePopupConfirm = (confirmed) => {
    if (confirmed && itemToDelete) {
      removeFromCart(itemToDelete);
    }
    setShowPopup(false);
    setItemToDelete(null);
  };

  const handleQuantityChange = (cartItem, newQuantity) => {
    try {
      if (newQuantity > 0) {
        updateCartItemQuantity(cartItem.id, newQuantity)
          .catch(error => {
            setSnackbar({
              open: true,
              message: error.message,
              severity: 'error'
            });
          });
      } else {
        handleRemoveFromCart(cartItem.id);
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to update quantity',
        severity: 'error'
      });
    }
  };

  const calculateCartTotal = () => {
    if (!cartItems || !productDocuments) return 0;

    return cartItems.reduce((acc, item) => {
      const product = getProduct(item.productId);
      if (!product) return acc;
      return acc + (item.discountedPrice || product.price) * item.quantity;
    }, 0);
  };

  const validateCartItems = async () => {
    const errors = [];

    for (const item of cartItems) {
      const product = getProduct(item.productId);
      if (!product) {
        errors.push(`Product "${item.productId}" is no longer available`);
        continue;
      }

      if (item.quantity > product.stock) {
        errors.push(`Only ${product.stock} units available for ${product.name}`);
      }

      if (product.minQuantity && item.quantity < product.minQuantity) {
        errors.push(`Minimum quantity required for ${product.name} is ${product.minQuantity}`);
      }

      if (product.maxQuantity && item.quantity > product.maxQuantity) {
        errors.push(`Maximum quantity allowed for ${product.name} is ${product.maxQuantity}`);
      }
    }

    return errors;
  };

  const handlePlaceOrder = async (orderProducts, orderPrice, address, paymentMethod) => {
    if (!address) {
      setSnackbar({
        open: true,
        message: 'Please select a delivery address',
        severity: 'error'
      });
      return;
    }

    setOrderLoading(true);
    try {
      const validationErrors = await validateCartItems();
      if (validationErrors.length > 0) {
        setSnackbar({
          open: true,
          message: validationErrors.join('. '),
          severity: 'error'
        });
        return;
      }

      const newOrder = await createOrder(
        orderProducts,
        orderPrice,
        address,
        paymentMethod
      );
      if (newOrder) {
        navigate(`/ordersummary/${newOrder.id}`);
      } else {
        throw new Error("Failed to create order");
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'Failed to place order',
        severity: 'error'
      });
    } finally {
      setOrderLoading(false);
    }
  };

  const groupCartItemsByCategory = () => {
    if (!cartItems || !productDocuments) return {};

    return cartItems.reduce((acc, item) => {
      const product = getProduct(item.productId);
      if (!product) return acc;

      const category = product.category || 'Other';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  };

  console.log(groupCartItemsByCategory())

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleProceedToPayment = () => {
    if (!selectedAddress) {
        setSnackbar({
            open: true,
            message: 'Please select a delivery address',
            severity: 'error'
        });
        return;
    }

    // Store cart details in localStorage
    localStorage.setItem('cartDetails', JSON.stringify({
        orderProducts: currentProductsInfo(cartItems),
        orderPrice: Math.ceil(calculateCartTotal() - (calculateCartTotal() * 10) / 100),
        selectedAddress
    }));

    // Navigate to payment page
    navigate('/onlinepayment');
  };

  if (!user) {
    return (
      <div className="cart2-page-empty">
        <h2>Please log in to view your cart</h2>
        <Link to="/login">
          <button>Login</button>
        </Link>
      </div>
    );
  }

  if (error) {
    return (
      <div className="cart2-page-empty">
        <h2>Error: {error}</h2>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={{ height: "100vh", padding: "10px" }}>
        <Skeleton count={5} height={100} className="mb-4" />
      </div>
    );
  }

  return (
    <>
      {!cartItems?.length ? (
        <div className="cart2-page-empty">
          <h2>Your Cart is Empty</h2>
          <Link to="/products">
            <button>Continue Shopping</button>
          </Link>
        </div>
      ) : (
        <div className="cart2-page">
          {isAddressModalOpen && (
            <div className="address-list-modal-overlay">
              <div className="address-list-modal">
                <div className="address-list-modal-header">
                  <h2>Select Address ( {user.addresses && user.addresses.length} )</h2>
                  <span
                    className="material-symbols-outlined"
                    onClick={() => setIsAddressModalOpen(false)}
                  >
                    close
                  </span>
                </div>

                <div className="address-list-modal-body">
                  {user.addresses && user.addresses.length >= 1
                    ?
                    user.addresses.map((address, index) => (
                      <div
                        key={index}
                        className={`address-list-modal-body-single-address ${tempSelectedAddress === address
                          ? "selected-address"
                          : ""
                          }`}
                        onClick={() => handleAddressSelection(address)}
                      >
                        <div className="address-list-modal-body-single-address-type">
                          <span className="material-symbols-outlined">
                            {address.type === "home"
                              ? "home"
                              : address.type === "work"
                                ? "work"
                                : "place"}
                          </span>
                          <span>
                            {address.type.charAt(0).toUpperCase() +
                              address.type.slice(1)}
                          </span>
                        </div>

                        <div className="address-list-modal-body-single-address-details">
                          <h4>{address.name}</h4>
                          <p>
                            {address.houseNumber}, {address.streetLineOne},
                            {address.streetLineTwo && `${address.streetLineTwo},`}
                            {address.landmark && `${address.landmark},`}
                            {address.city}, {address.pincode}, {address.state},
                            {address.country}
                          </p>
                        </div>
                      </div>
                    ))
                    :
                    <h4>No address found</h4>
                  }
                </div>

                <div className="address-list-modal-footer">
                  <button onClick={() => navigate("/address")}>Manage More</button>

                  <div>
                    <button onClick={() => setIsAddressModalOpen(false)}>
                      Cancel
                    </button>
                    {user.addresses && user.addresses.length > 1 && (
                      <button onClick={handleSaveAddress}>Save</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="cart2-info">
            <div className="cart2-details">
              <div className="cart2-header">
                <h2>Cart</h2>
                <h2>{cartItems.length} Items</h2>
              </div>
              <div className="cart2-items">
                <div className="cart2-item-header">
                  <span className="cart2-item-product">Product</span>
                  <span className="cart2-item-price">Price</span>
                  <span className="cart2-item-quantity">Quantity</span>
                  <span className="cart2-item-total">Total</span>
                </div>

                {Object.entries(groupCartItemsByCategory()).map(([category, items]) => (
                  <Accordion
                    key={category}
                    defaultExpanded
                    sx={{
                      margin: '1rem 0',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      '&:before': {
                        display: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        backgroundColor: '#f8f8f8',
                        width: '100%',
                        '& .MuiAccordionSummary-content': {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '90%',
                          margin: '12px 0',
                        },
                      }}
                    >
                      <Typography variant="h6" sx={{ color: '#333', flexShrink: 0 }}>
                        {category} ({items.length} items)
                      </Typography>

                      <Typography variant="h6" sx={{ color: '#333', marginLeft: 'auto', marginRight: '16px' }}>
                        {currencyDocument.symbol} {
                          items.reduce((total, item) => {
                            const product = getProduct(item.productId);
                            return total + ((item.discountedPrice || product.price) * item.quantity);
                          }, 0)
                        }
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ padding: '1rem' }}>
                      {items.map((item) => {
                        const product = getProduct(item.productId);
                        if (!product) return null;

                        return (
                          <div className="cart2-item" key={item.id}>
                            <div className="cart2-item-product">
                              <img src={product.images[0]} alt={product.name} />
                              <div className="cart2-item-detail">
                                <h3>{product.name}</h3>
                                {(item.color || item.size || item.variantName) && (
                                  <div className="cart-2-item-variant-info">
                                    <div className="cart-2-item-variant-info-name">
                                      <span>{item.variantName}</span>
                                    </div>

                                    <div>
                                      {item.color && (
                                        <div className="cart-2-item-variant-info-color">
                                          <span>Color:</span>
                                          <div
                                            style={{
                                              backgroundColor: item.color.color,
                                              borderRadius: "50%",
                                              width: "15px",
                                              height: "15px",
                                              border: "1px solid black",
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                      {item.size && (
                                        <div className="cart-2-item-variant-info-size">
                                          <span>Size: {item.size}</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <div className="cart2-item-misc-functionality">
                                  <div className="cart2-item-sfl">
                                    <span className="material-symbols-outlined">
                                      bookmark
                                    </span>
                                    <span>Wishlist</span>
                                  </div>
                                  <div
                                    className="cart2-item-remove"
                                    onClick={() => handleRemoveFromCart(item.id)}
                                  >
                                    <span className="material-symbols-outlined">
                                      delete
                                    </span>
                                    <span>Remove</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="cart2-item-actions-container">
                              <div className="cart2-item-price">
                                <h5>
                                  {currencyDocument.symbol}
                                  {item.discountedPrice || product.price}
                                </h5>
                              </div>

                              <div className="cart2-item-quantityhandler">
                                <button onClick={() => handleQuantityChange(item, item.quantity - 1)}>
                                  -
                                </button>
                                <span>{item.quantity}</span>
                                <button onClick={() => handleQuantityChange(item, item.quantity + 1)}>
                                  +
                                </button>
                              </div>

                              <div className="cart2-item-itemTotal">
                                <h5>
                                  {currencyDocument.symbol}
                                  {((item.discountedPrice || product.price) * item.quantity).toFixed(2)}
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>

          <div className="cart2-summary-secondary">
            <div className="cart2-address-details">
              <div className="cart2-address-header">
                <h2>Shipping Address</h2>
                {
                  user.addresses && user.addresses.length >= 1
                    ?
                    <button onClick={handleAddressModal}>Change Address</button>
                    :
                    ""
                }
              </div>

              <div className="cart2-address-list">
                {user.addresses && user.addresses.length >= 1 ? (
                  <div className="cart2-single-address">
                    <div className="cart2-single-address-type">
                      <span className="material-symbols-outlined">
                        {selectedAddress.type === "home"
                          ? "home"
                          : selectedAddress.type === "work"
                            ? "work"
                            : "place"}
                      </span>
                      <span>
                        {selectedAddress.type.charAt(0).toUpperCase() +
                          selectedAddress.type.slice(1)}
                      </span>
                    </div>

                    <div className="cart2-single-address-details">
                      <div className="cart2-single-address-info">
                        <h4>{selectedAddress.name}</h4>
                        <p>
                          {selectedAddress.houseNumber},{" "}
                          {selectedAddress.streetLineOne},
                          {selectedAddress.streetLineTwo &&
                            `${selectedAddress.streetLineTwo},`}
                          {selectedAddress.landmark &&
                            `${selectedAddress.landmark},`}
                          {selectedAddress.city}, {selectedAddress.pincode},{" "}
                          {selectedAddress.state},{selectedAddress.country}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="add-new-address">
                    <h5>No Addresses Found</h5>
                    <button onClick={() => navigate("/address")}>
                      Add New
                    </button>
                  </div>
                )}
              </div>
            </div>



            <div className="cart2-summary">
              <h2>Cart Summary</h2>

              <div className="cart2-summary-details">
                <div className="cart2-summary-order-total">
                  <h5>Cart Total</h5>
                  <h5>
                    {currencyDocument.symbol}
                    {calculateCartTotal()}
                  </h5>
                </div>

                <div className="cart2-summary-order-shipping">
                  <h5>Shipping</h5>
                  <h5>Free</h5>
                </div>

                <div className="cart2-summary-order-discount">
                  <h5>Discount</h5>
                  <h5>10%</h5>
                </div>
              </div>

              <hr className="cart2-summary-hr" />

              <div className="cart2-summary-final-payment-detail">
                <div className="cart2-summary-final-amount">
                  <h5>Order Total</h5>
                  <h5>
                    {currencyDocument.symbol}
                    {Math.ceil(
                      calculateCartTotal() - (calculateCartTotal() * 10) / 100
                    )}
                  </h5>
                </div>
              </div>

              <hr className="cart2-summary-hr" />

              <div className="cart2-payment-method">
                  <h4>Choose a Payment Method</h4>
                  <div className="cart2-payment-method-list">
                    <div className={`cart2-payment-method-single ${selectedPaymentMethod === "cod" ? "selected-payment-method" : ""}`} onClick={() => handlePaymentMethodChange("cod")}>
                      <span className="material-symbols-outlined">payments</span>
                      <span>Cash on Delivery</span>
                    </div>

                    <div className={`cart2-payment-method-single ${selectedPaymentMethod === "online" ? "selected-payment-method" : ""}`} onClick={() => handlePaymentMethodChange("online")}>
                      <span className="material-symbols-outlined">credit_card</span>
                        <span>Online Payment</span>
                    </div>
                  </div>
                </div>

                {user.addresses && user.addresses.length === 0 ? (
                  <div>
                    <button onClick={() => navigate("/address")}>Add Address</button>
                  </div>
                ) : (
                  selectedPaymentMethod === "cod" ?
                  <>
                    <button
                      onClick={() =>
                        handlePlaceOrder(
                          currentProductsInfo(cartItems),
                          Math.ceil(
                            calculateCartTotal() - (calculateCartTotal() * 10) / 100
                          ),
                          selectedAddress,
                          "cod"
                        )
                      }
                    >

                      {
                        orderLoading ? (
                          <div className="cart2-summary-final-amount-loading">
                            <div className="cart2-summary-final-amount-loading-spinner"></div>
                            <span>Placing Order...</span>
                          </div>
                        ) : (
                          `Place Order for ${currencyDocument.symbol} ${Math.ceil(
                            calculateCartTotal() - (calculateCartTotal() * 10) / 100
                          )}`
                        )
                      }

                    </button>
                  </>
                  :
                  <button 
                    className="cart2-summary-online-ptp" 
                    onClick={handleProceedToPayment}
                  >
                    <span className="material-symbols-outlined">assured_workload</span> 
                    Proceed to Payment
                  </button>
                  )}
            </div>
          </div>

          <Popup
            showPopupFlag={showPopup}
            setShowPopupFlag={setShowPopup}
            msg={popupMessage}
            setPopupReturn={handlePopupConfirm}
          />

          <CustomSnackbar
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
            onClose={handleSnackbarClose}
          />
        </div>
      )}
    </>
  );
};

export default Cart;
