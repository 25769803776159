import React, { useEffect, useState } from "react";

//importing scss
import "./UserDetail.scss";
import { useDocument } from "../firebase files/hooks/useDocument";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Modal } from "react-bootstrap";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import Select from "react-select";
const UserDetail = () => {
  const [roles, setRoles] = useState([]);
  const { userProfileId } = useParams();
  const { user } = useAuthContext();

  // get and update code start
  const { document: userProfileDoc, error: userProfileError } = useDocument(
    "users",
    userProfileId
  );

  console.log(userProfileDoc, userProfileError);

  const { updateDocument, response: responseUpdateDocument } =
    useFirestore("users");
  // get and update code end

  // get user
  const { documents: dbUsers, error: dbuserserror } = useCollection("users");
  const [dbUserState, setdbUserState] = useState(dbUsers);

  // code for active inactive start
  // Make sure that userProfileDoc is not null before using it
  const [status, setStatus] = useState(userProfileDoc?.status ?? "active");
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [reason, setReason] = useState("");
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to handle status changes (active/inactive)
  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setShowPopup(true);
    setPopupData({ status: newStatus });
  };

  // Add a state to track the error message
  const [errorForNoSelectReasonMessage, setErrorForNoSelectReasonMessage] =
    useState("");

  // Function to handle the submission of the status change
  const handlePopupSubmit = async () => {
    // Check if a reason is selected when the status is inactive
    if (popupData.status === "inactive" && !reason) {
      setErrorForNoSelectReasonMessage(
        "Please select a reason before updating the status."
      );
      return; // Don't proceed if no reason is selected
    } else {
      setErrorForNoSelectReasonMessage(""); // Clear error message when a reason is selected
    }

    setLoading(true);
    try {
      const currentDate = new Date();

      const updateData = {
        status: popupData.status,
        activeBy: popupData.status === "active" ? user.uid : null, // replace with your logic
        activeAt: popupData.status === "active" ? currentDate : null, // replace with your logic
        inactiveBy: popupData.status === "inactive" ? user.uid : null,
        inactiveAt: popupData.status === "inactive" ? currentDate : null,
        inactiveReason: popupData.status === "inactive" ? reason : null, // Add reason here
        inactiveRemark: popupData.status === "inactive" ? remark : null, // Add remark here
      };

      // Add to the 'inactiveByAt' array to track status changes
      if (popupData.status === "inactive") {
        //   // Append inactive status details to the map
        updateData.inactiveByAt = userProfileDoc.inactiveByAt || [];
        updateData.inactiveByAt.push({
          inactiveBy: user.uid, // Replace with actual user who is marking inactive
          inactiveAt: currentDate, // Store the current timestamp
          inactiveReason: reason, // Add the selected reason here
          inactiveRemark: remark, // Add the remark here
        });
      }

      if (popupData.status === "active") {
        // Append active status details to the map
        updateData.activeByAt = userProfileDoc.activeByAt || [];
        updateData.activeByAt.push({
          activeBy: user.uid, // Replace with actual user who is marking active
          activeAt: currentDate, // Store the current timestamp
        });
      }

      // Call function to update the document in the database (replace this with your update logic)
      await updateDocument(userProfileId, updateData);
      setShowPopup(false);
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setLoading(false);
    }
  };
  // code for active inactive end

  // code for isemployee start
  const [isEmployee, setIsEmployee] = useState(false); // Default is 'false'
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedEmployeeStatus(value); // Set the selected value ("yes" or "no")
    setShowConfirmationPopup(true); // Show the confirmation popup
  };
  const handleUpdateIsEmployee = async () => {
    try {
      const updatedStatus = selectedEmployeeStatus === "yes" ? true : false;
      // Update in the database (assuming you have a function to do this)
      await updateDocument(userProfileId, { isEmployee: updatedStatus });

      setIsEmployee(updatedStatus); // Update the state to reflect the change
      setShowConfirmationPopup(false); // Close the popup
    } catch (error) {
      console.error("Error updating employee status:", error);
    }
  };
  // code for isemployee end

  // full code for employee detail start
  const [employeeId, setEmployeeId] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [uanNumber, setUanNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [employeeDetailUpdateMessage, setEmployeeDetailUpdateMessage] =
    useState("");
  const [isEdUpdating, setIsEdUpdating] = useState(false);
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [dateOfLeaving, setDateOfLeaving] = useState("");
  const [managerName, setManagerName] = useState("");
  const [isEdEditing, setIsEdEditing] = useState(false);
  const [edMessageType, setEdMessageType] = useState("");
  const handleEdEditClick = () => {
    setIsEdEditing(!isEdEditing);
  };
  const handleEdCancelClick = () => {
    setIsEdEditing(!isEdEditing);
    if (userProfileDoc) {
      setEmployeeId(userProfileDoc.employeeId || "");
      setDepartment(userProfileDoc.department || "");
      setDesignation(userProfileDoc.designation || "");
      setUanNumber(userProfileDoc.uanNumber || "");
      setPanNumber(userProfileDoc.panNumber || "");
      setAadhaarNumber(userProfileDoc.aadhaarNumber || "");
      setDateOfJoining(userProfileDoc.dateOfJoining || "");
      setDateOfLeaving(
        userProfileDoc.dateOfJoining ? userProfileDoc.dateOfLeaving || "" : ""
      );
      setManagerName(userProfileDoc.managerName || "");
    }
  };

  useEffect(() => {
    if (userProfileDoc) {
      setEmployeeId(userProfileDoc.employeeId || "");
      setDepartment(userProfileDoc.department || "");
      setDesignation(userProfileDoc.designation || "");
      setUanNumber(userProfileDoc.uanNumber || "");
      setPanNumber(userProfileDoc.panNumber || "");
      setAadhaarNumber(userProfileDoc.aadhaarNumber || "");
      setDateOfJoining(userProfileDoc.dateOfJoining || "");
      setDateOfLeaving(userProfileDoc.dateOfLeaving || "");
      setManagerName(userProfileDoc.managerName || "");
    } else {
      // Set default values for new entries
      setEmployeeId("");
      setDepartment("");
      setDesignation("");
      setUanNumber("");
      setPanNumber("");
      setAadhaarNumber("");
      setDateOfJoining("");
      setDateOfLeaving("");
      setManagerName("");
    }
  }, [userProfileDoc]);

  // Department and Designation Dropdowns
  const departmentOptions = [
    { value: "it", label: "IT" },
    { value: "operation", label: "Operation" },
    { value: "marketing", label: "Marketing" },
    { value: "hr", label: "HR" },
  ];

  const designationOptions = [
    { value: "manager", label: "Manager" },
    { value: "tester", label: "Tester" },
    { value: "developer", label: "Developer" },
    { value: "hr", label: "HR" },
    // Add more options here
  ];

  const handleUpdateEmployeeDetails = async () => {
    // Validation for required fields
    if (
      !employeeId ||
      !dateOfJoining ||
      !panNumber ||
      !aadhaarNumber ||
      !department ||
      !designation ||
      department.length === 0
    ) {
      setEmployeeDetailUpdateMessage("Please fill all required fields.");
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000); // Clear message after 5 seconds

      return;
    }

    // Validation for UAN number (exactly 12 digits)
    if (uanNumber && !/^\d{12}$/.test(uanNumber)) {
      setEmployeeDetailUpdateMessage("UAN number must be exactly 12 digits.");
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    // Validation for PAN card (minimum 10 characters)
    if (panNumber.length < 10) {
      setEmployeeDetailUpdateMessage(
        "PAN card must have at least 10 characters."
      );
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    // Remove spaces from the formatted Aadhaar number for validation
    const rawAadhaarNumber = aadhaarNumber.replace(/\s/g, "");

    // Validation for Aadhaar number (exactly 12 digits)
    if (!/^\d{12}$/.test(rawAadhaarNumber)) {
      setEmployeeDetailUpdateMessage(
        "Aadhaar number must be exactly 12 digits."
      );
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    // Validation for date of joining and leaving
    if (
      !dateOfJoining ||
      (dateOfLeaving && new Date(dateOfLeaving) <= new Date(dateOfJoining))
    ) {
      setEmployeeDetailUpdateMessage(
        "Please ensure the Date of Joining is set and Date of Leaving is after the Date of Joining."
      );
      setEdMessageType("error_msg");
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000);
      return;
    }

    const dataSet = {
      employeeId,
      department,
      designation,
      uanNumber,
      panNumber,
      aadhaarNumber,
      dateOfJoining,
      dateOfLeaving,
      managerName,
    };

    setIsEdUpdating(true);
    setEmployeeDetailUpdateMessage("");

    try {
      await updateDocument(userProfileId, dataSet);
      setEdMessageType("success_msg");
      setEmployeeDetailUpdateMessage("Employee details updated successfully!");
      setTimeout(() => {
        setIsEdEditing(!isEdEditing);
      }, 4000);
    } catch (error) {
      console.error("Error updating employee details:", error);
      setEdMessageType("error_msg");
      setEmployeeDetailUpdateMessage(
        "Failed to update employee details. Please try again."
      );
    } finally {
      setIsEdUpdating(false);
      setTimeout(() => {
        setEmployeeDetailUpdateMessage("");
        setEdMessageType("");
      }, 4000); // Clear message after 5 seconds
    }
  };

  // full code for employee detail end

  // full code for vehicle detail start

  const [vehicleNumberPlate, setVehicleNumberPlate] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("true");
  const [vehicleType, setVehicleType] = useState("");
  const [drivingLicence, setDrivingLicence] = useState("");
  const [vehicleDetailUpdateMessage, setVehicleDetailUpdateMessage] =
    useState("");
  const [isVdUpdating, setIsVdUpdating] = useState(false);
  const [isVdEditing, setIsVdEditing] = useState(false);
  const [vdMessageType, setVdMessageType] = useState("");
  const handleVdEditClick = () => {
    setIsVdEditing(!isVdEditing);
  };
  const handleVdCancelClick = () => {
    setIsVdEditing(!isVdEditing);
    if (userProfileDoc) {
      setVehicleNumberPlate(userProfileDoc.vehicleNumberPlate || "");
      setVehicleStatus(userProfileDoc.vehicleStatus || "");
      setVehicleType(userProfileDoc.vehicleType || "");
      setDrivingLicence(userProfileDoc.drivingLicence || "");
    }
  };

  useEffect(() => {
    if (userProfileDoc) {
      setVehicleNumberPlate(userProfileDoc.vehicleNumberPlate || "");
      setVehicleStatus(userProfileDoc.vehicleStatus || "");
      setVehicleType(userProfileDoc.vehicleType || "");
      setDrivingLicence(userProfileDoc.drivingLicence || "");
    } else {
      // Set default values for new entries
      setVehicleNumberPlate("");
      setVehicleStatus("");
      setVehicleType("");
      setDrivingLicence("");
    }
  }, [userProfileDoc]);
  console.log("vehicleStatus", vehicleStatus);

  const handleUpdateVehicleDetail = async () => {
    // Validation for required fields
    if (
      vehicleStatus === "" ||
      vehicleStatus === undefined ||
      vehicleStatus === null
    ) {
      setVehicleDetailUpdateMessage("Please select vehicle status.");
      setVdMessageType("error_msg");
      setTimeout(() => {
        setVehicleDetailUpdateMessage("");
        setVdMessageType("");
      }, 4000); // Clear message after 5 seconds
      return;
    }

    const dataSet = {
      vehicleNumberPlate,
      vehicleStatus,
      vehicleType,
      drivingLicence,
    };

    setIsVdUpdating(true);
    setVehicleDetailUpdateMessage("");

    try {
      await updateDocument(userProfileId, dataSet);
      setVdMessageType("success_msg");
      setVehicleDetailUpdateMessage("Vehicle details updated successfully!");
      setTimeout(() => {
        setIsVdEditing(!isVdEditing);
      }, 4000);
    } catch (error) {
      console.error("Error updating vehicle details:", error);
      setVdMessageType("error_msg");
      setVehicleDetailUpdateMessage(
        "Failed to update vehicle details. Please try again."
      );
    } finally {
      setIsVdUpdating(false);
      setTimeout(() => {
        setVehicleDetailUpdateMessage("");
        setVdMessageType("");
      }, 4000); // Clear message after 5 seconds
    }
  };

  // full code for full code for vehicle detail end

  // full code for bank detail start
  const [bankDetailFormData, setBankDetailFormData] = useState({
    acHolderName: "",
    acNumber: "",
    bankName: "",
    branchName: "",
    ifscCode: "",
  });

  // Update BankDetailFormData when userProfileDoc changes
  useEffect(() => {
    if (userProfileDoc) {
      setBankDetailFormData({
        acHolderName: userProfileDoc.bankDetail?.acHolderName || "",
        acNumber: userProfileDoc.bankDetail?.acNumber || "",
        bankName: userProfileDoc.bankDetail?.bankName || "",
        branchName: userProfileDoc.bankDetail?.branchName || "",
        ifscCode: userProfileDoc.bankDetail?.ifscCode || "",
      });
    }
  }, [userProfileDoc]);

  const [isBankDetailSaving, setIsBankDetailSaving] = useState(false);
  const [isBankDetailEditing, setIsBankDetailEditing] = useState(false);
  const [saveBankDetailMessage, setSaveBankDetailMessage] = useState("");
  const [BankDetailMessageType, setBankDetailMessageType] = useState("");

  // Toggle Edit Mode
  const handleBankDetailEditClick = () => {
    setIsBankDetailEditing(!isBankDetailEditing);
  };

  const handleBankDetailCancelClick = () => {
    if (userProfileDoc) {
      setBankDetailFormData({
        acHolderName: userProfileDoc.bankDetail?.acHolderName || "",
        acNumber: userProfileDoc.bankDetail?.acNumber || "",
        bankName: userProfileDoc.bankDetail?.bankName || "",
        branchName: userProfileDoc.bankDetail?.branchName || "",
        ifscCode: userProfileDoc.bankDetail?.ifscCode || "",
      });
    }
    setIsBankDetailEditing(!isBankDetailEditing);
  };

  // Handle Input Changes
  const handleBankDetailChange = (e) => {
    const { name, value } = e.target;
    setBankDetailFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate Fields
  const validateBankDetailForm = () => {
    if (
      !bankDetailFormData.acHolderName ||
      !bankDetailFormData.acNumber ||
      !bankDetailFormData.bankName ||
      !bankDetailFormData.ifscCode
    ) {
      setSaveBankDetailMessage("Please fill all required fields.");
      setBankDetailMessageType("error_msg");
      setTimeout(() => {
        setSaveBankDetailMessage("");
        setBankDetailMessageType("");
      }, 4000);
      return false;
    }

    return true;
  };

  // Submit Form Data
  const handleBankDetailSubmit = async () => {
    if (!validateBankDetailForm()) return;

    setIsBankDetailSaving(true);
    setSaveBankDetailMessage("");

    try {
      const updatedData = {
        ...userProfileDoc,
        bankDetail: bankDetailFormData,
      };

      await updateDocument(userProfileId, updatedData);
      setBankDetailMessageType("success_msg");
      setSaveBankDetailMessage("Updated successfully!");

      setTimeout(() => {
        setIsBankDetailEditing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating bank details:", error);
      setBankDetailMessageType("error_msg");
      setSaveBankDetailMessage(
        "Failed to update bank details. Please try again."
      );
    } finally {
      setIsBankDetailSaving(false);
      setTimeout(() => {
        setSaveBankDetailMessage("");
        setBankDetailMessageType("");
      }, 4000);
    }
  };
  // full code for bank detail end

  // full code for ref1 start
  const [ref1FormData, setRef1FormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });

  // Update ref1FormData when userProfileDoc changes
  useEffect(() => {
    if (userProfileDoc) {
      setRef1FormData({
        name: userProfileDoc.ref1?.name || "",
        phone: userProfileDoc.ref1?.phone || "",
        email: userProfileDoc.ref1?.email || "",
        address: userProfileDoc.ref1?.address || "",
      });
    }
  }, [userProfileDoc]);

  const [isRef1Saving, setIsRef1Saving] = useState(false);
  const [isRef1Editing, setIsRef1Editing] = useState(false);
  const [saveRef1Message, setSaveRef1Message] = useState("");
  const [ref1MessageType, setRef1MessageType] = useState("");

  // Toggle Edit Mode
  const handleRef1EditClick = () => {
    setIsRef1Editing(!isRef1Editing);
  };

  const handleRef1CancelClick = () => {
    if (userProfileDoc) {
      setRef1FormData({
        name: userProfileDoc.ref1?.name || "",
        phone: userProfileDoc.ref1?.phone || "",
        email: userProfileDoc.ref1?.email || "",
        address: userProfileDoc.ref1?.address || "",
      });
    }
    setIsRef1Editing(!isRef1Editing);
  };
  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRef1FormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePhone = (value) => {
    setRef1FormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  // Validate Fields
  const validateRef1Form = () => {
    if (!ref1FormData.name || !ref1FormData.phone || !ref1FormData.address) {
      setSaveRef1Message("Please fill all required fields.");
      setRef1MessageType("error_msg");
      setTimeout(() => {
        setSaveRef1Message("");
        setRef1MessageType("");
      }, 4000);
      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (ref1FormData.email && !emailRegex.test(ref1FormData.email)) {
      setSaveRef1Message("Please enter a valid email address.");
      setRef1MessageType("error_msg");
      setTimeout(() => {
        setSaveRef1Message("");
        setRef1MessageType("");
      }, 4000);
      return false;
    }

    return true;
  };

  // Submit Form Data
  const handleRef1Submit = async () => {
    if (!validateRef1Form()) return;

    setIsRef1Saving(true);
    setSaveRef1Message("");

    try {
      const updatedData = {
        ...userProfileDoc,
        ref1: ref1FormData,
      };

      await updateDocument(userProfileId, updatedData);
      setRef1MessageType("success_msg");
      setSaveRef1Message("Updated successfully!");

      setTimeout(() => {
        setIsRef1Editing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating reference 1 details:", error);
      setRef1MessageType("error_msg");
      setSaveRef1Message(
        "Failed to update Reference 1 details. Please try again."
      );
    } finally {
      setIsRef1Saving(false);
      setTimeout(() => {
        setSaveRef1Message("");
        setRef1MessageType("");
      }, 4000);
    }
  };
  // full code for ref1 end

  // full code for ref2 start
  const [ref2FormData, setRef2FormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });

  // Update ref2FormData when userProfileDoc changes
  useEffect(() => {
    if (userProfileDoc) {
      setRef2FormData({
        name: userProfileDoc.ref2?.name || "",
        phone: userProfileDoc.ref2?.phone || "",
        email: userProfileDoc.ref2?.email || "",
        address: userProfileDoc.ref2?.address || "",
      });
    }
  }, [userProfileDoc]);

  const [isRef2Saving, setIsRef2Saving] = useState(false);
  const [isRef2Editing, setIsRef2Editing] = useState(false);
  const [saveRef2Message, setSaveRef2Message] = useState("");
  const [ref2MessageType, setRef2MessageType] = useState("");

  // Toggle Edit Mode
  const handleRef2EditClick = () => {
    setIsRef2Editing(!isRef2Editing);
  };

  const handleRef2CancelClick = () => {
    if (userProfileDoc) {
      setRef2FormData({
        name: userProfileDoc.ref2?.name || "",
        phone: userProfileDoc.ref2?.phone || "",
        email: userProfileDoc.ref2?.email || "",
        address: userProfileDoc.ref2?.address || "",
      });
    }
    setIsRef2Editing(!isRef2Editing);
  };

  // Handle Input Changes
  const handleRef2Change = (e) => {
    const { name, value } = e.target;
    setRef2FormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeRef2Phone = (value) => {
    setRef2FormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  // Validate Fields
  const validateRef2Form = () => {
    if (!ref2FormData.name || !ref2FormData.phone || !ref2FormData.address) {
      setSaveRef2Message("Please fill all required fields.");
      setRef2MessageType("error_msg");
      setTimeout(() => {
        setSaveRef2Message("");
        setRef2MessageType("");
      }, 4000);
      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (ref2FormData.email && !emailRegex.test(ref2FormData.email)) {
      setSaveRef2Message("Please enter a valid email address.");
      setRef2MessageType("error_msg");
      setTimeout(() => {
        setSaveRef2Message("");
        setRef2MessageType("");
      }, 4000);
      return false;
    }

    return true;
  };

  // Submit Form Data
  const handleRef2Submit = async () => {
    if (!validateRef2Form()) return;

    setIsRef2Saving(true);
    setSaveRef2Message("");

    try {
      const updatedData = {
        ...userProfileDoc,
        ref2: ref2FormData,
      };

      await updateDocument(userProfileId, updatedData);
      setRef2MessageType("success_msg");
      setSaveRef2Message("Updated successfully!");

      setTimeout(() => {
        setIsRef2Editing(false);
      }, 4000);
    } catch (error) {
      console.error("Error updating reference 1 details:", error);
      setRef2MessageType("error_msg");
      setSaveRef2Message(
        "Failed to update Reference 1 details. Please try again."
      );
    } finally {
      setIsRef2Saving(false);
      setTimeout(() => {
        setSaveRef2Message("");
        setRef2MessageType("");
      }, 4000);
    }
  };
  // full code for ref2 end

  return (
    <div className="user-details">
      <div className="user-details-container">
        <div className="user-details-layout">
          {/* left column */}
          <div className="profile-card animate-slide-in">
            <div className="profile-photo">
              <img
                src={
                  userProfileDoc?.photoURL ?? "/assets/img/profile-option-1.png"
                }
                alt="profilt-photo"
              />
              <div
                style={{ backgroundColor: "#fff" }}
                className="online-indicator"
              >
                <div
                  className={`status-indicator ${
                    userProfileDoc?.online ? "online" : "offline"
                  }`}
                />
              </div>
            </div>
            <div className="profile-info">
              <h2>{userProfileDoc?.fullName ?? "John Doe"}</h2>
              <div className="contact">
                <span>
                  {userProfileDoc?.phoneNumber.replace(
                    /(\d{2})(\d{5})(\d{5})/,
                    "+$1 $2-$3"
                  ) ?? "+91 98765-43210"}
                </span>
              </div>
              <div className="contact">
                <span>{userProfileDoc?.email ?? "JohnDoe@gmail.com"}</span>
              </div>
              <div className="contact">
                <span>
                  {userProfileDoc?.city ?? " "},{" "}
                  {userProfileDoc?.country ?? " "}{" "}
                </span>
              </div>
            </div>
          </div>
          {/* right column */}

          <div className="profile-detail">
            <div className="pd-cards animate-slide-in">
              <div className="pd-info">
                <div className="info-heading">
                  <div>
                    <label>On-boarded</label>
                    <p>
                      {userProfileDoc?.createdAt
                        ? format(
                            userProfileDoc.createdAt.toDate(),
                            "dd-MMM-yyyy"
                          )
                        : ""}
                    </p>
                  </div>
                  <div>
                    <label>Last Active</label>
                    <p>
                      {userProfileDoc && userProfileDoc.lastLoginTimestamp
                        ? format(
                            userProfileDoc.lastLoginTimestamp.toDate(),
                            "dd-MMM-yyyy hh:mm a"
                          )
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="info-detail">
                  <div className="status">
                    <div className="theme_radio_container">
                      <div className="radio_single">
                        <input
                          type="radio"
                          id="active"
                          name="user_status"
                          value="active"
                          checked={userProfileDoc?.status === "active"}
                          onChange={() => handleStatusChange("active")}
                        />
                        <label htmlFor="active">
                          <div className="label_inner">
                            {userProfileDoc && userProfileDoc.activeAt
                              ? "Active"
                              : "Make Active"}

                            {userProfileDoc &&
                              userProfileDoc.activeBy &&
                              userProfileDoc.activeAt && (
                                <div className="info_icon">
                                  <span className="material-symbols-outlined">
                                    info
                                  </span>
                                  <div className="info_icon_inner">
                                    <b className="text_green">Active</b> by{" "}
                                    <b>
                                      {userProfileDoc &&
                                        dbUserState &&
                                        dbUserState.find(
                                          (user) =>
                                            user.id === userProfileDoc.activeBy
                                        )?.fullName}
                                    </b>{" "}
                                    on{" "}
                                    <b>
                                      {userProfileDoc &&
                                        format(
                                          userProfileDoc.activeAt.toDate(),
                                          "dd-MMM-yyyy hh:mm a"
                                        )}
                                    </b>
                                  </div>
                                </div>
                              )}
                          </div>
                        </label>
                      </div>
                      <div className="radio_single">
                        <input
                          type="radio"
                          id="inactive"
                          name="user_status"
                          checked={userProfileDoc?.status === "inactive"}
                          onChange={() => handleStatusChange("inactive")}
                        />
                        <label htmlFor="inactive">
                          <div className="label_inner">
                            {userProfileDoc && userProfileDoc.inactiveAt
                              ? "Inactive"
                              : "Make Inactive"}

                            {userProfileDoc &&
                              userProfileDoc.inactiveAt &&
                              userProfileDoc.inactiveBy && (
                                <div className="info_icon">
                                  <span className="material-symbols-outlined">
                                    info
                                  </span>
                                  <div className="info_icon_inner">
                                    <b className="text_red">Inactive</b> by{" "}
                                    <b>
                                      {userProfileDoc &&
                                        dbUserState &&
                                        dbUserState.find(
                                          (user) =>
                                            user.id ===
                                            userProfileDoc.inactiveBy
                                        )?.fullName}
                                    </b>{" "}
                                    on{" "}
                                    {userProfileDoc &&
                                      format(
                                        userProfileDoc.inactiveAt.toDate(),
                                        "dd-MMM-yyyy hh:mm a"
                                      )}
                                    , Reason{" "}
                                    {userProfileDoc &&
                                      userProfileDoc.inactiveReason &&
                                      userProfileDoc.inactiveReason}
                                    {/* {userProfileDoc &&
                                      userProfileDoc.inactiveRemark && (
                                        <>
                                          {" "}
                                          Remark{" "}
                                          <b>{userProfileDoc.inactiveRemark}</b>
                                        </>
                                      )} */}
                                  </div>
                                </div>
                              )}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="modal-popup">
                      <Modal
                        show={showPopup}
                        onHide={() => setShowPopup(false)}
                        centered
                      >
                        <Modal.Header
                          className="justify-content-center"
                          style={{
                            border: "none",
                            backgroundColor: "#f0f5ff",
                          }}
                        >
                          <h5>
                            {popupData.status === "inactive"
                              ? "Reason For Inactivity"
                              : "Confirmation"}
                          </h5>
                        </Modal.Header>
                        <Modal.Body
                          className="text-center"
                          style={{ fontSize: "18px" }}
                        >
                          {popupData.status === "inactive" && (
                            <div
                              className="form_container"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              <div
                                className="radio_group"
                                style={{
                                  display: "grid",
                                  alignItems: "center",
                                  textAlign: "left",
                                }}
                              >
                                <div className="radio_single">
                                  <input
                                    type="radio"
                                    name="reason"
                                    value="Security Concerns"
                                    checked={reason === "Security Concerns"}
                                    onChange={() =>
                                      setReason("Security Concerns")
                                    }
                                    id="SecurityConcerns"
                                  />
                                  <label
                                    htmlFor="SecurityConcerns"
                                    style={{ padding: "2px" }}
                                  >
                                    Security Concerns
                                  </label>
                                </div>
                                <div className="radio_single">
                                  <input
                                    type="radio"
                                    name="reason"
                                    value="Policy Violations"
                                    checked={reason === "Policy Violations"}
                                    onChange={() =>
                                      setReason("Policy Violations")
                                    }
                                    id="PolicyViolations"
                                  />
                                  <label
                                    htmlFor="PolicyViolations"
                                    style={{ padding: "2px" }}
                                  >
                                    Policy Violations
                                  </label>
                                </div>
                                {userProfileDoc?.isEmployee && (
                                  <div className="radio_single">
                                    <input
                                      type="radio"
                                      name="reason"
                                      value="Resigned"
                                      checked={reason === "Resigned"}
                                      onChange={() => setReason("Resigned")}
                                      id="Resigned"
                                    />
                                    <label
                                      htmlFor="Resigned"
                                      style={{ padding: "2px" }}
                                    >
                                      Resigned
                                    </label>
                                  </div>
                                )}
                                <div className="radio_single">
                                  <input
                                    type="radio"
                                    name="reason"
                                    value="Other"
                                    checked={reason === "Other"}
                                    onChange={() => setReason("Other")}
                                    id="Other"
                                  />
                                  <label
                                    htmlFor="Other"
                                    style={{ padding: "2px" }}
                                  >
                                    Other
                                  </label>
                                </div>
                              </div>

                              <div
                                className="textarea_container"
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                }}
                              >
                                <textarea
                                  value={remark}
                                  onChange={(e) => setRemark(e.target.value)}
                                  placeholder=" Enter any remark..."
                                  style={{ width: "250px" }}
                                ></textarea>
                              </div>
                            </div>
                          )}
                          <span style={{ color: "rgb(250, 98, 98)" }}>
                            Are you sure you want to mark this user as{" "}
                            {popupData.status}?
                          </span>
                        </Modal.Body>
                        <Modal.Footer
                          className="d-flex justify-content-between"
                          style={{
                            border: "none",
                            gap: "15px",
                          }}
                        >
                          {errorForNoSelectReasonMessage && (
                            <div
                              style={{
                                fontSize: "15px",
                                padding: "4px 15px",
                                borderRadius: "8px",
                                background: "#ffe9e9",
                                color: "red",
                                width: "fit-content",
                                margin: "auto",
                              }}
                            >
                              {errorForNoSelectReasonMessage}
                            </div>
                          )}
                          <div
                            className="done_btn"
                            onClick={() => setShowPopup(false)}
                            style={{ cursor: "pointer" }}
                          >
                            <button className="theme_btn">No</button>
                          </div>
                          <div
                            className="cancel_btn"
                            onClick={handlePopupSubmit}
                            disabled={loading}
                            style={{ cursor: "pointer" }}
                          >
                            <button className="theme_btn">
                              {loading ? "Saving..." : "Yes, Update"}
                            </button>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                  <div className="status">
                    <h6>Is Employee? - </h6>
                    <div className="theme_radio_container">
                      <div className="radio_single">
                        <input
                          type="radio"
                          id="yes"
                          name="emp_status"
                          checked={
                            userProfileDoc && userProfileDoc.isEmployee === true
                          }
                          onChange={() => handleRadioChange("yes")}
                        />
                        <label htmlFor="yes">Yes</label>
                      </div>
                      <div className="radio_single">
                        <input
                          type="radio"
                          id="no"
                          name="emp_status"
                          checked={
                            userProfileDoc &&
                            userProfileDoc.isEmployee === false
                          }
                          onChange={() => handleRadioChange("no")}
                        />
                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                    <Modal
                      show={showConfirmationPopup}
                      onHide={() => setShowConfirmationPopup(false)}
                      centered
                    >
                      <Modal.Header
                        className="justify-content-center"
                        style={{
                          backgroundColor: "#f0f5ff",
                          border: "none",
                        }}
                      >
                        <h5>Confirmation</h5>
                      </Modal.Header>
                      <Modal.Body
                        className="text-center"
                        style={{
                          color: "#FA6262",
                          fontSize: "20px",
                          border: "none",
                        }}
                      >
                        Are you sure you want to mark this user as{" "}
                        {selectedEmployeeStatus === "yes"
                          ? "an employee"
                          : "not an employee"}
                        ?
                      </Modal.Body>
                      <Modal.Footer
                        className="d-flex justify-content-between"
                        style={{
                          border: "none",
                          gap: "15px",
                        }}
                      >
                        <div
                          className="done_btn theme_btn"
                          onClick={() => setShowConfirmationPopup(false)}
                        >
                          No
                        </div>
                        <div
                          className="cancel_btn theme_btn"
                          onClick={handleUpdateIsEmployee}
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "Yes, Update"}
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div style={{ display: "flex", gap: "16px" }}>
                    <div className="current-role">
                      <h6>Current Role - </h6>
                      <p>{userProfileDoc?.role ?? "No role"}</p>
                    </div>
                    <div className="current-mode">
                      <p
                        className="online-status"
                        style={
                          userProfileDoc?.online
                            ? { backgroundColor: "#22c55e" }
                            : { backgroundColor: "#e74747" }
                        }
                      />
                      <p
                        style={
                          userProfileDoc?.online
                            ? { color: "#22c55e" }
                            : { color: "#e74747" }
                        }
                      >
                        {userProfileDoc?.online ? "online" : "offline"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Roles card */}
            <div className="pd-cards animate-slide-in">
              <div className="pd-heading">
                <h2>Roles</h2>
              </div>
              <div className="pd-data">
                {roles.length === 0 ? (
                  <p style={{ paddingLeft: "8px" }}>No roles yet provided</p>
                ) : (
                  <div className="theme_radio_container">
                    {/* Roles data */}
                    {roles.map((role, index) => (
                      <div className="radio_single" key={index}>
                        <input
                          type="checkbox"
                          id={`${role}`}
                          name="fc-role"
                          value={`${role}`}
                          // onChange={() => handleRoleChange(index)}
                        />
                        <label htmlFor={`${role}`}>{role}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="pd-cards animate-slide-in">
              <div className="pd-heading">
                <h2>Access management</h2>
              </div>
              <div className="pd-data">
                <p style={{ paddingLeft: "8px" }}>No access yet provided</p>
              </div>
            </div>

            {userProfileDoc?.isEmployee && (
              <div className="pd-cards animate-slide-in">
                <div className="pd-heading">
                  <h2>Employee Details</h2>
                </div>
                <div className="pd-data">
                  {!isEdEditing && (
                    <>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/VisitingDays.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Date of joining</h6>
                          <p>
                            {userProfileDoc && userProfileDoc.dateOfJoining
                              ? format(
                                  new Date(userProfileDoc.dateOfJoining), // Ensure it's converted to a Date object
                                  "dd-MMM-yyyy"
                                )
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/VisitingDays.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Date of Leaving</h6>
                          <p>
                            {" "}
                            {userProfileDoc && userProfileDoc.dateOfLeaving
                              ? format(
                                  new Date(userProfileDoc.dateOfLeaving), // Ensure it's converted to a Date object
                                  "dd-MMM-yyyy"
                                )
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/id.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Employee ID</h6>
                          <p>
                            {" "}
                            {userProfileDoc && userProfileDoc.employeeId
                              ? userProfileDoc.employeeId
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/department.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Department</h6>
                          <p>
                            {" "}
                            {userProfileDoc && userProfileDoc.department
                              ? userProfileDoc.department.value
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/designation.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Designation</h6>
                          <p>
                            {userProfileDoc && userProfileDoc.designation
                              ? userProfileDoc.designation.value
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/id-card.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>UAN Number</h6>
                          <p>
                            {userProfileDoc && userProfileDoc.uanNumber
                              ? userProfileDoc.uanNumber
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/id-card.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>PAN Number</h6>
                          <p>
                            {" "}
                            {userProfileDoc && userProfileDoc.panNumber
                              ? userProfileDoc.panNumber
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/id-card.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Aadhar Number</h6>
                          <p>
                            {" "}
                            {userProfileDoc && userProfileDoc.aadhaarNumber
                              ? userProfileDoc.aadhaarNumber
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {isEdEditing && (
                    <>
                      <div className="row row_gap form_full mt-4">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>Date of Joining*</label>
                            <div className="form_field_inner">
                              <input
                                type="date"
                                value={dateOfJoining}
                                onChange={(e) =>
                                  setDateOfJoining(e.target.value)
                                }
                                placeholder="Select Date of Joining"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>Employee ID*</label>
                            <div className="form_field_inner">
                              <input
                                type="number"
                                value={employeeId}
                                // onChange={(e) => setEmployeeId(e.target.value)}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  if (newValue.length <= 4) {
                                    setEmployeeId(newValue);
                                  }
                                }}
                                maxLength="4"
                                placeholder="Enter Employee ID"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>Department*</label>
                            <div className="form_field_inner">
                              <Select
                                // isMulti
                                value={department}
                                onChange={setDepartment}
                                options={departmentOptions}
                                placeholder="Select Department"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>Designation*</label>
                            <div className="form_field_inner">
                              <Select
                                value={designation}
                                onChange={setDesignation}
                                options={designationOptions}
                                placeholder="Select Designation"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>Manager Name*</label>
                            <div className="form_field_inner">
                              <input
                                type="text"
                                value={managerName}
                                onChange={(e) => setManagerName(e.target.value)}
                                placeholder="Enter Manager Name"
                                onKeyPress={(e) => {
                                  const regex = /^[a-zA-Z\s]*$/; // Only letters and spaces allowed
                                  if (!regex.test(e.key)) {
                                    e.preventDefault(); // Prevent invalid input
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>UAN Number</label>
                            <div className="form_field_inner">
                              <input
                                type="text"
                                value={uanNumber}
                                // onChange={(e) => setUanNumber(e.target.value)}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  if (newValue.length <= 12) {
                                    setUanNumber(newValue);
                                  }
                                }}
                                placeholder="Enter UAN Number"
                                maxLength="12"
                                style={{
                                  textTransform: "uppercase",
                                }}
                              />
                            </div>
                          </div>
                        </div>{" "}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>PAN Number*</label>
                            <div className="form_field_inner">
                              <input
                                type="text"
                                value={panNumber}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\s+/g,
                                    ""
                                  ); // Remove spaces
                                  if (newValue.length <= 10) {
                                    setPanNumber(newValue);
                                  }
                                }}
                                style={{
                                  textTransform: "uppercase",
                                }}
                                placeholder="Enter PAN Number"
                                maxLength="10"
                              />
                            </div>
                          </div>
                        </div>{" "}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form_field label_top">
                            <label>Aadhaar Number*</label>
                            <div className="form_field_inner">
                              <input
                                type="text"
                                value={aadhaarNumber}
                                onChange={(e) => {
                                  const input = e.target.value.replace(
                                    /\s/g,
                                    ""
                                  ); // Remove any existing spaces
                                  if (
                                    /^\d*$/.test(input) &&
                                    input.length <= 12
                                  ) {
                                    // Allow only digits and max 12 characters
                                    const formatted =
                                      input
                                        .match(/.{1,4}/g) // Group digits in chunks of 4
                                        ?.join(" ") || ""; // Join chunks with a space
                                    setAadhaarNumber(formatted);
                                  }
                                }}
                                placeholder="Enter Aadhaar Number"
                              />
                            </div>
                          </div>
                        </div>
                        {userProfileDoc && userProfileDoc.dateOfJoining && (
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form_field label_top">
                              <label>Date of Leaving</label>
                              <div className="form_field_inner">
                                <input
                                  type="date"
                                  value={dateOfLeaving}
                                  onChange={(e) =>
                                    setDateOfLeaving(e.target.value)
                                  }
                                  min={dateOfJoining} // Ensure only dates after Date of Joining can be selected
                                  placeholder="Select Date of Leaving"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="btn_msg_area">
                        {employeeDetailUpdateMessage && (
                          <p className={`msg_area ${edMessageType}`}>
                            {employeeDetailUpdateMessage}
                          </p>
                        )}

                        <button
                          onClick={handleEdCancelClick}
                          disabled={isEdUpdating}
                          className={`theme_btn btn_border no_icon min_width ${
                            isEdUpdating ? "disabled" : ""
                          }`}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleUpdateEmployeeDetails}
                          disabled={isEdUpdating}
                          className={`theme_btn btn_fill no_icon min_width ${
                            isEdUpdating ? "disabled" : ""
                          }`}
                        >
                          {isEdUpdating ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="pd-cards animate-slide-in">
              <div className="pd-heading">
                <h2>Vehicle Details</h2>
              </div>
              <div className="pd-data">
                {!isVdEditing && (
                  <>
                    <div className="pd-data-element">
                      <div className="pd-icon">
                        <img src="/assets/img/steering-wheel.png" alt="" />
                      </div>
                      <div className="pd-data-value">
                        <h6>Is Vehicle</h6>
                        <p>
                          {userProfileDoc &&
                          userProfileDoc.vehicleStatus === true
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                    {userProfileDoc && userProfileDoc.vehicleStatus === true ? (
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img
                            src={`/assets/img/${
                              userProfileDoc
                                ? userProfileDoc.vehicleType === "2-Wheeler"
                                  ? "bike.png"
                                  : userProfileDoc.vehicleType === "4-Wheeler"
                                  ? "car.png"
                                  : "vehicles.png"
                                : "vehicles.png"
                            }`}
                            alt=""
                          />
                        </div>
                        <div className="pd-data-value">
                          <h6>Vehicle Type</h6>

                          <p>
                            {userProfileDoc && userProfileDoc.vehicleType
                              ? userProfileDoc.vehicleType
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {userProfileDoc && userProfileDoc.vehicleStatus === true ? (
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/numberplate.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Number Plate</h6>
                          <p>
                            {userProfileDoc && userProfileDoc.vehicleNumberPlate
                              ? userProfileDoc.vehicleNumberPlate
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {userProfileDoc && userProfileDoc.vehicleStatus === true ? (
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/id-card.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Driving Licence</h6>
                          <p>
                            {userProfileDoc && userProfileDoc.drivingLicence
                              ? userProfileDoc.drivingLicence
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="pd-cards animate-slide-in">
              <div className="pd-heading">
                <h2>Employee Document</h2>
              </div>
              <div className="pd-data">
                <p style={{ paddingLeft: "8px" }}>No document yet provided</p>
              </div>
            </div>
            <div className="pd-cards animate-slide-in">
              <div className="pd-heading">
                <h2>Bank Detail</h2>
              </div>
              <div className="pd-data">
                {!isBankDetailEditing && (
                  <>
                    <div className="pd-data-element">
                      <div className="pd-icon">
                        <img src="/assets/img/accountholder.png" alt="" />
                      </div>
                      <div className="pd-data-value">
                        <h6>A/C Holder Name</h6>
                        <p>
                          {userProfileDoc?.bankDetail?.acHolderName
                            ? userProfileDoc.bankDetail.acHolderName
                            : "Not provided yet"}
                        </p>
                      </div>
                    </div>
                    <div className="pd-data-element">
                      <div className="pd-icon">
                        <img src="/assets/img/accountnumber.png" alt="" />
                      </div>
                      <div className="pd-data-value">
                        <h6>A/C Number</h6>
                        <p>
                          {userProfileDoc?.bankDetail?.acNumber
                            ? userProfileDoc.bankDetail.acNumber
                            : "Not provided yet"}
                        </p>
                      </div>
                    </div>
                    <div className="pd-data-element">
                      <div className="pd-icon">
                        <img src="/assets/img/bank.png" alt="" />
                      </div>
                      <div className="pd-data-value">
                        <h6>Bank Name</h6>
                        <p>
                          {userProfileDoc?.bankDetail?.bankName
                            ? userProfileDoc.bankDetail.bankName
                            : "Not provided yet"}
                        </p>
                      </div>
                    </div>
                    <div className="pd-data-element">
                      <div className="pd-icon">
                        <img src="/assets/img/branch.png" alt="" />
                      </div>
                      <div className="pd-data-value">
                        <h6>Branch Name</h6>
                        <p>
                          {userProfileDoc?.bankDetail?.branchName
                            ? userProfileDoc.bankDetail.branchName
                            : "Not provided yet"}
                        </p>
                      </div>
                    </div>
                    <div className="pd-data-element">
                      <div className="pd-icon">
                        <img src="/assets/img/ifsccode.png" alt="" />
                      </div>
                      <div className="pd-data-value">
                        <h6>IFSC Code</h6>
                        <p>
                          {userProfileDoc?.bankDetail?.ifscCode
                            ? userProfileDoc.bankDetail.ifscCode
                            : "Not provided yet"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {/* {isBankDetailEditing &&} */}
              </div>
            </div>
            {userProfileDoc && userProfileDoc.isEmployee && (
              <div className="pd-cards animate-slide-in">
                <div className="pd-heading">
                  <h2>Reference 1</h2>
                </div>
                <div className="pd-data">
                  {!isRef1Editing && (
                    <>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/user.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Name</h6>
                          <p>
                            {userProfileDoc.ref1 && userProfileDoc.ref1.name
                              ? userProfileDoc.ref1.name
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/mobile-phone.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Phone</h6>
                          <p>
                            {userProfileDoc.ref1 && userProfileDoc.ref1.phone
                              ? userProfileDoc.ref1.phone.replace(
                                  /(\d{2})(\d{5})(\d{5})/,
                                  "+$1 $2-$3"
                                )
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/mail.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Email</h6>
                          <p>
                            {userProfileDoc.ref1 && userProfileDoc.ref1.email
                              ? userProfileDoc.ref1.email
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/pin.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Address</h6>
                          <p>
                            {userProfileDoc.ref1 && userProfileDoc.ref1.address
                              ? userProfileDoc.ref1.address
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {isRef2Editing && <></>} */}
                </div>
              </div>
            )}
            {userProfileDoc && userProfileDoc.isEmployee && (
              <div className="pd-cards animate-slide-in">
                <div className="pd-heading">
                  <h2>Reference 2</h2>
                </div>
                <div className="pd-data">
                  {!isRef2Editing && (
                    <>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/user.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Name</h6>
                          <p>
                            {userProfileDoc.ref2 && userProfileDoc.ref2.name
                              ? userProfileDoc.ref2.name
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/mobile-phone.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Phone</h6>
                          <p>
                            {userProfileDoc.ref2 && userProfileDoc.ref2.phone
                              ? userProfileDoc.ref2.phone.replace(
                                  /(\d{2})(\d{5})(\d{5})/,
                                  "+$1 $2-$3"
                                )
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/mail.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Email</h6>
                          <p>
                            {userProfileDoc.ref2 && userProfileDoc.ref2.email
                              ? userProfileDoc.ref2.email
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                      <div className="pd-data-element">
                        <div className="pd-icon">
                          <img src="/assets/img/pin.png" alt="" />
                        </div>
                        <div className="pd-data-value">
                          <h6>Address</h6>
                          <p>
                            {userProfileDoc.ref2 && userProfileDoc.ref2.address
                              ? userProfileDoc.ref2.address
                              : "Not provided yet"}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {isRef2Editing && <></>} */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
