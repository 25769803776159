import React from "react";
import { Route, Routes } from "react-router-dom";

// import css
import "../corporateOneCss/CorporateOneColor.scss";
import "../corporateOneCss/CorporateOneFont.scss";
import "../corporateOneCss/CorporateOneStyle.scss";
import "../corporateOneCss/CorporateOneForm.scss";
import GlobalRoute from "../../../../global route/GlobalRoute";
import PGDashboard from "../../../../allRoles/dashboards/PGDashboard";
// import pages
// import CorporateOneHeader from '../corporateOneComponent/CorporateOneHeader';
import CorporateOneHome from "../corporateOnePages/corporateOneHome/CorporateOneHome";
import Navbar from "../../../../default components/Navbar";
import CorporateOneFooter from "../corporateOneComponent/CorporateOneFooter";
import CorporateFooterBottom from "../corporateOneComponent/CorporateFooterBottom";

const CorporateOneRoute = () => {
  return (
    <div className="corporate_one">
      {/* <CorporateOneHeader/> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<CorporateOneHome />}></Route>
        <Route path="/dashboard" element={<PGDashboard />}></Route>
      </Routes>
      <GlobalRoute />
      <CorporateOneFooter />
      <CorporateFooterBottom />
    </div>
  );
};

export default CorporateOneRoute;
