import { React, useState, useEffect } from "react";
import "../IconsSettings.scss";
import "./HideUnhidePages.scss";
import { useAuthContext } from "../../../firebase files/hooks/useAuthContext";
import { useDocument } from "../../../firebase files/hooks/useDocument";
import { useFirestore } from "../../../firebase files/hooks/useFirestore";
import { MDBCol, MDBRow, MDBCardText } from "mdb-react-ui-kit";
import ReactSwitch from "react-switch";

const HideUnhidePages = () => {
  const { user } = useAuthContext();
  const { updateDocument, deleteDocument } = useFirestore("settings");
  const [pageControlDocState, setPageControlDocState] = useState(null);

  const { document: pageControlDoc, error: pageControlDocerror } = useDocument(
    "settings",
    "pagesControl"
  );

  useEffect(() => {
    setPageControlDocState(pageControlDoc);
  }, [pageControlDoc]);

  console.log("Test console",pageControlDoc)

  const pageTypeLabels = {
    career: "Career",
    home: "Home",
    features: "Features",
    gallery: "Gallery",
    products: "Products",
    returnpolicy: "Return Policy",
    dashboard: "Dashboard",
    cart: "Cart",
    about: "About",
    login: "Login",
    orders: "Orders",
    accommodationBooking: "Accommodation Booking",
  };

  const handleToggleChange = async (fieldName, currentValue) => {
    try {
      await updateDocument("pagesControl", {
        [fieldName]: currentValue,
      });
    } catch (error) {
      console.error("Error updating page status:", error);
    }
  };
  

  return (
    <div>
      <div className="hide-unhide-pages-parent-div">
        {user && user.role === "admin" && (
          <div>
            <div className="radio-container menu-and-submenu-table-container">
              <div className="section-title">
                <p>Hide-Unhide Pages : </p>
              </div>
              {pageControlDocState &&
                Object.entries(pageControlDocState)
                  .filter(([fieldName]) => fieldName !== "id")
                  .sort(([a], [b]) =>
                    pageTypeLabels[a].localeCompare(pageTypeLabels[b])
                  )
                  .map(([fieldName, value]) => (
                    <div
                      key={fieldName}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div style={{ marginTop: "30px" }}>
                        <h4
                          style={{
                            fontWeight: "700",
                            color: "var(--heading-color)",
                          }}
                        >
                          {pageTypeLabels[fieldName]}
                        </h4>
                        <p>Page Visibility</p>
                      </div>

                      {/* ACTIVE INACTIVE SWITCH */}
                      <div style={{ margin: "0px 0px 20px 15px" }}>
                        <MDBRow>
                          <MDBCol className="col-9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex align-items-center mt-2">
                                <div>
                                  <p
                                    style={{ color: "red" }}
                                  >
                                    Inactive
                                  </p>
                                </div>

                                <ReactSwitch
                                  checked={value}
                                  onChange={() =>
                                    handleToggleChange(fieldName, !value)
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  <p
                                    style={{ color: "red" }}
                                  >
                                    Active
                                  </p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  ))}
              <br />
              <p style={{ fontSize: "13px" }}>
                <span style={{ fontWeight: "bold", color: "var(--red-color)" }}>
                  Note :
                </span>
                &nbsp;Page Settings : You can customize the visibility settings for the
                icons on your page according to your preference
              </p>
            </div>
          </div>
        )}
        <br />

      </div>
    </div>
  );
};

export default HideUnhidePages;
