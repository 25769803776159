import React, { useState, useEffect } from "react";
import "./AdminVendors.scss";
import { useFirestore } from "../../../../firebase files/hooks/useFirestore";
// import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import { useCollection } from "../../../../firebase files/hooks/useCollection";

const AdminVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editVendor, setEditVendor] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    city: "",
  });

  const { documents: vendorsDocuments, error: vendorsError } =
    useCollection("vendors");

  const { addDocument, deleteDocument, updateDocument, response } =
    useFirestore("vendors");

  useEffect(() => {
    if (vendorsDocuments) {
      setVendors(vendorsDocuments);
    }
  }, [vendorsDocuments]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editVendor) {
      await updateDocument(editVendor.id, formData);
    } else {
      await addDocument(formData);
    }

    if (!response.error) {
      setShowForm(false);
      setEditVendor(null);
      resetForm();
    }
  };

  const handleEdit = (vendor) => {
    setEditVendor(vendor);
    setFormData({
      name: vendor.name,
      city: vendor.city,
    });
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this vendor?")) {
      await deleteDocument(id);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      city: "",
    });
  };

  const handleCancel = () => {
    setShowForm(false);
    setEditVendor(null);
    resetForm();
  };

  return (
    <div className="admin-vendors">
      <div className="header">
        <h1>Location Management</h1>
        <button className="add-button" onClick={() => setShowForm(true)}>
          Add New Location
          <span className="material-symbols-outlined">add</span>
        </button>
      </div>

      {vendorsError && <p className="error">{vendorsError}</p>}

      {showForm ? (
        <form onSubmit={handleSubmit} className="vendor-form">
          <h2>{editVendor ? "Edit Location" : "Add New Location"}</h2>
          <input
            type="text"
            name="name"
            placeholder="Location Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="city"
            placeholder="Location City"
            value={formData.city}
            onChange={handleInputChange}
            required
          />
          <div className="form-buttons">
            <button type="submit">
              {editVendor ? "Update Location" : "Add Location"}
            </button>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div className="vendors-grid">
          {vendorsDocuments &&
            vendorsDocuments.map((vendor) => (
              <div key={vendor.id} className="vendor-card">
                <h3>{vendor.name}</h3>
                <p>
                  <strong>City:</strong> {vendor.city}
                </p>
                <div className="card-actions">
                  <button
                    onClick={() => handleEdit(vendor)}
                    className="edit-button"
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </button>
                  <button
                    onClick={() => handleDelete(vendor.id)}
                    className="delete-button"
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AdminVendors;
