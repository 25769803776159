import { useState, useEffect } from "react";
import Adcarousel from "../../default components/Ads";
import { useCollection } from "../hooks/useCollection";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { projectFirestore, timestamp } from "../../firebase config/config";
import { useFirestore } from "../hooks/useFirestore";
import { useSignupPhone } from "../hooks/useSignupPhone";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useAuthContext } from "../hooks/useAuthContext";
import { projectAuthObj, projectAuth } from "../../firebase config/config";

// FIREBASE
import { useDocument } from "../../firebase files/hooks/useDocument";

// styles
import "./PGloginsignup.css";
import { displayName } from "react-quill";

function camelCase(str) {
  return (
    str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      // .replace(/\s/g, '')
      .replace(/^(.)/, function (b) {
        return b.toUpperCase();
      })
  );
}

// Simple email validation regex
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export default function PGLoginPhone() {
  const [showOtpInput, setShowOtpInput] = useState(false);
  const { loggedInUser } = useAuthContext();

  const { document: dbOrganizationDocuments, error: dbOrganizationError } =
    useDocument("settings", "organisation");

  const { document: dbPageNavigationDocuments, error: dbPageNavigationError } =
    useDocument("settings", "PageNavigations");

  const { document: dbAuthTypeDocuments, error: dbAuthTypeError } =
    useDocument("settings", "AuthType");

  // use states
  const [user, setUser] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountryName] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const [otptimer, setOtpTimer] = useState(20);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const { setUpRecapcha, resendOTP } = useSignupPhone();
  const [confirmObj, setConfirmObj] = useState("");
  const [userName, setUserName] = useState("");
  const [mobilenoSliderState, setmobilenoSliderState] = useState(true);
  const [otpSliderState, setotpSliderState] = useState(false);
  const [newUserSliderState, setnewUserSliderState] = useState(false);
  const [genderSelectionSliderState, setGenderSelectionSliderState] =
    useState(true);
  const [newUserDetailsSliderState, setNewUserDetailsSliderState] =
    useState(false);
  const navigate = useNavigate();
  const [resendOTPFlag, setResendOTPFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true); // Default to checked
  const [selectedGender, setSelectedGender] = useState("");
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [isEmail, setIsEmail] = useState(""); // State for email
  const [password, setPassword] = useState(""); // State for password
  const { updateDocument, response: responseUpdateDocument } =
    useFirestore("users");

  const { documents: dbUsers, error: dbuserserror } = useCollection("users");


  // Validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle login with email and password
  const handleEmailPasswordLogin = async () => {
    if (!validateEmail(isEmail)) {
      setError("Please enter a valid email.");
      return;
    }

    setIsLoading(true); // Show loading spinner
    setError(""); // Clear previous error
    console.log("password is :", password, "and email is :", isEmail);

    try {
      // Firebase signInWithEmailAndPassword
      const userCredential = await projectAuth.signInWithEmailAndPassword(isEmail, password);
      const user = userCredential.user;
      console.log("password is :", password, "and email is :", isEmail);

      if (user) {
        // Redirect to home page after successful login
        navigate("/home");
      }
    } catch (error) {
      setError(error.message); // Show error if login fails
    } finally {
      setIsLoading(false); // Hide loading spinner after completion
    }
  };

  const handleWheel = (e) => {
    // Prevent scrolling changes
    e.preventDefault();
  };

  useEffect(() => {
    let interval;
    if (otpSliderState && otptimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (otptimer === 0) {
      setIsResendDisabled(false); // Enable the Resend button when the timer reaches zero
    }

    return () => clearInterval(interval);
  }, [activeTab, otptimer]);

  const handleResendOtp = () => {
    // Logic to resend OTP
    setOtpTimer(20);
    setIsResendDisabled(true);
  };

  // Google authentication
  const signInWithGoogle = async () => {
    const provider = new projectAuthObj.GoogleAuthProvider();
    try {
      const result = await projectAuth.signInWithPopup(provider);
      const user = result.user;

      if (!user) {
        console.error("No user returned from Google sign-in");
        return;
      }

      // Check if user exists in Firestore
      const userRef = projectFirestore.collection("users").doc(user.uid);
      const userSnap = await userRef.get();

      if (userSnap.exists) {
        console.log("Existing user signed in with Google");
        await updateDocument(user.uid, {
          online: true,
          email: user.email,
          lastLoginTimestamp: timestamp.fromDate(new Date()),
        });
      } else {
        console.log("New user detected, showing gender selection");
        setnewUserSliderState(true);
        setGenderSelectionSliderState(true);
        setNewUserDetailsSliderState(true);

        let firstName = user.displayName?.split(" ")[0] || "";
        let imgUrl = user.photoURL || "/assets/img/dummy_user.png";

        await userRef.set({
          online: true,
          displayName: firstName,
          fullName: user.displayName,
          gender: "",
          phoneNumber: user.phoneNumber || "",
          email: user.email,
          city: "",
          address: "",
          country: "",
          countryCode: "",
          role: "customer",
          roles: ["customer"],
          photoURL: imgUrl,
          status: "active",
          createdAt: timestamp.fromDate(new Date()),
          lastLoginTimestamp: timestamp.fromDate(new Date()),
        });
      }

      // Manually update the authentication context
      projectAuth.onAuthStateChanged((authUser) => {
        if (authUser) {
          console.log("User updated in AuthContext:", authUser);
        } else {
          console.error("User sign-in failed");
        }
      });

    } catch (error) {
      console.error("Google Sign-in Error:", error);
    }
  };


  //Link Google Account with phone number
  const linkGoogleAccount = (curuser) => {
    const provider = new projectAuthObj.GoogleAuthProvider();

    curuser
      .linkWithPopup(provider)
      .then(async (result) => {
        const user = result.user;
        // Accounts successfully linked
        console.log("Accounts successfully linked", user);
        await updateDocument(user.uid, {
          email: user.email,
        });
      })
      .catch((error) => {
        // Handle Errors here.
        console.error("Error linking accounts", error);
      });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checked state
  };

  // GENDER SELECTION START
  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setGenderSelectionSliderState(false);
    setNewUserDetailsSliderState(true);
  };
  // GENDER SELECTION END

  //   send opt
  const getOTP = async (e) => {
    e.preventDefault();
    setOtpTimer(20);
    setIsResendDisabled(true);
    setError("");
    if (phone === "" || phone === undefined || phone.length < 10) {
      return setError("Please enter valid mobile number");
    }

    try {
      setIsLoading(true);
      let btnSendOTP = document.getElementById("btn_sendotp");
      btnSendOTP.style.display = "none";
      const respons = await setUpRecapcha("+" + phone);
      setConfirmObj(respons);
      setmobilenoSliderState(false);
      setotpSliderState(true);
      setIsLoading(false);
      // setnewUserSliderState(false);
    } catch (error) {
      console.log("2 error.message", error.message);
      setError(error.message);
      await resendOTP("+" + phone);
      // let obj_maintenance = document.getElementById("btn_sendotp");
      // obj_maintenance.style.display = "block";
      setIsLoading(false); // Stop the loader
    }
  };

  // OTP verify
  const verifyOTP = async (e) => {
    e.preventDefault();
    setmobilenoSliderState(false);
    if (otp === "" || otp === undefined || otp === null || otp < 6) {
      return setError("Please enter six digit OTP");
    }
    setIsLoading(true); // Start the loader
    setError("");

    try {
      await confirmObj.confirm(otp).then(async (result) => {
        const user = result.user;
        setUser(user);

        // Check if the user is new
        if (result.additionalUserInfo.isNewUser) {
          setUserName(user.displayName);

          // Split the full name by space
          let splitName = userName.split(" ");

          // Extract the first name
          let firstName = splitName[0];

          let imgUrl = "/assets/img/dummy_user.png";

          await user.updateProfile({
            phoneNumber: phone,
            displayName: firstName,
            photoURL: imgUrl,
          });

          projectFirestore
            .collection("users")
            .doc(user.uid)
            .set({
              online: true,
              displayName: firstName,
              fullName: userName,
              gender: "",
              phoneNumber: phone,
              email: "",
              city,
              address,
              country,
              countryCode,
              role: "customer",
              roles: ["customer"],
              photoURL: imgUrl,
              accessType: "country",
              accessValue: "India",
              status: "active",
              createdAt: timestamp.fromDate(new Date()),
              lastLoginTimestamp: timestamp.fromDate(new Date()),
            });

          setnewUserSliderState(true);
          setotpSliderState(false);
          setIsLoading(false);
        } else {
          console.log("Existing user signed in with phone number");
          setIsLoading(true);

          await updateDocument(user.uid, {
            online: true,
            lastLoginTimestamp: timestamp.fromDate(new Date()),
          });

          console.log("user role", user)
          console.log("logged in user", loggedInUser)

          if (dbPageNavigationDocuments) {
            if (dbPageNavigationDocuments.afterLogin === "") {
              navigate("/profile");
            } else {
              navigate(dbPageNavigationDocuments.afterLogin);
            }
          } else {
            navigate("/profile"); // Redirect to profile if the document doesn't exist
          }

          // {
          //   user && user.role === "admin"
          //   ? navigate("/profile")
          //     : navigate("/dashboard")
          // }

          setotpSliderState(false);
          setnewUserSliderState(false);
          setGenderSelectionSliderState(false);
          setNewUserDetailsSliderState(false);
          setIsLoading(false); // Stop the loader
        }
      });
    } catch (error) {
      console.log("error.message", error.message);
      setError(
        "Given OTP is not valid, please enter the valid OTP sent to your number"
      );
      setIsLoading(false)

      setTimeout(function () {
        setError("");
        setResendOTPFlag(true);
      }, 30000);
    }
  };

  // Toggle OTP visibility
  const toggleOtpVisibility = () => {
    setIsOtpVisible(!isOtpVisible);
  };

  // New User Form
  const newUserForm = async () => {
    setmobilenoSliderState(false);
    setotpSliderState(false);
    setGenderSelectionSliderState(false);
    setIsLoading(true);

    let errFlag = false;
    if (!errFlag) {
      let splitName = name.split(" ");

      // Extract the first name
      displayName = splitName.length > 0 ? splitName[0] : name;

      await updateDocument(user.uid, {
        displayName: camelCase(displayName.toLowerCase()),
        fullName: camelCase(name.toLowerCase()),
        email,
        gender: selectedGender,
        city: camelCase(city.toLowerCase()),
      });

      if (dbPageNavigationDocuments) {
        if (dbPageNavigationDocuments.afterLogin === "") {
          navigate("/profile");
        } else {
          navigate(dbPageNavigationDocuments.afterLogin);
        }
      } else {
        navigate("/profile"); // Redirect to profile if the document doesn't exist
      }

      setnewUserSliderState(false);
      setIsLoading(false); // Stop the loader
    }
  };

  const handlePhoneChange = (value, countryData) => {
    // setPhone(value);
    // setCountry(countryData);
    console.log(
      "value: ",
      value + " country code: ",
      countryData.countryCode + ", country name: ",
      countryData.name
    );
    setPhone(value);
    setCountryCode(countryData.countryCode);
    setCountryName(countryData.name);
  };

  return (
    <div className="form_page">
      <div className="container">
        {/* START CODE FOR LOGIN/SIGNUP VIA EMAIL PASSWORD */}
        {dbAuthTypeDocuments &&
          (dbAuthTypeDocuments.currentAuth === "EmailPassword" ||
            dbAuthTypeDocuments.currentAuth === "GoogleAndEmailPassword" ||
            dbAuthTypeDocuments.currentAuth === "PhoneAndEmailPassword") && (
            <div>
              {mobilenoSliderState && (
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    <h5 className="mt-4 mb-2">Email</h5>
                  </div>

                  <div className="new_form_field with_icon phoneinput">
                    <div>
                      <TextField
                        label="Email"
                        id="email"
                        type="email"
                        value={isEmail}
                        onChange={(e) => setIsEmail(e.target.value)} // Update setter to setIsEmail
                        required
                        fullWidth
                        margin="normal"
                      />

                      {/* Password Input */}
                      <TextField
                        label="Password"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        fullWidth
                        margin="normal"
                      />
                      {error && (
                        <Alert variant="danger" className="mt-2">
                          {error}
                        </Alert>
                      )}
                    </div>
                  </div>

                  <div className="">
                    {!isLoading && (
                      <>
                        <div className="d-flex justify-content-center flex-column">
                          <button
                            className="btn_fill"
                            onClick={handleEmailPasswordLogin}

                          >
                            Confirm
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {isLoading && (
                    <div className="text-center">
                      <h6 className="text_green mb-2">Submitting</h6>
                      <BeatLoader
                        color={"var(--click-color)"}
                        loading={true}
                      />
                    </div>
                  )}
                </>
              )}
            </div>)}
        {/* END CODE FOR LOGIN/SIGNUP VIA EMAIL PASSWORD */}

        <div className="fp_inner">
          <div className="form-parnt">
            <div>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-6 login-adcarousel-img">
                  <Adcarousel></Adcarousel>
                </div>
                <div className="col-md-6">
                  <div>
                    {dbAuthTypeDocuments &&
                      !(dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle" ||
                        dbAuthTypeDocuments.currentAuth === "GoogleAndEmailPassword" ||
                        dbAuthTypeDocuments.currentAuth === "PhoneAndEmailPassword") && (
                        <div className="d-flex justify-content-center align-items-center login_pge_logo_div">
                          <img
                            src={
                              dbOrganizationDocuments &&
                              dbOrganizationDocuments.logoUrl
                            }
                            alt="site logo"
                          />
                        </div>
                      )}

                    {/* START CODE FOR LOGIN/SIGNUP VIA PHONE OTP */}
                    {dbAuthTypeDocuments &&
                      (dbAuthTypeDocuments.currentAuth === "Phone" ||
                        dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle" ||
                        dbAuthTypeDocuments.currentAuth === "PhoneAndEmailPassword") && (
                        <div>
                          {mobilenoSliderState && (
                            <>
                              <div className="d-flex justify-content-center align-items-center">
                                <h5 className="mt-4 mb-2">Mobile Number</h5>
                              </div>

                              <div className="new_form_field with_icon phoneinput">
                                <div>
                                  <PhoneInput
                                    country={"in"}
                                    // onlyCountries={['in', 'us', 'ae']}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    international
                                    keyboardType="phone-pad"
                                    // countryCallingCodeEditable={false}
                                    countryCodeEditable={true}
                                    // disableCountryCode={true}
                                    placeholder="Country code + mobile number"
                                    inputProps={{
                                      name: "phone",
                                      required: true,
                                      autoFocus: false,
                                    }}
                                    inputStyle={{
                                      width: "100%",
                                      height: "45px",
                                      paddingLeft: "45px",
                                      fontSize: "16px",
                                      borderRadius: "5px",
                                      border: "1px solid var(--click-color)",
                                    }}
                                  ></PhoneInput>
                                  {error && (
                                    <Alert variant="danger" className="mt-2">
                                      {error}
                                    </Alert>
                                  )}
                                </div>
                              </div>

                              <div
                                id="recapcha-container"
                                style={{
                                  marginTop: "20px",
                                }}
                              ></div>

                              <div className="">
                                {!isLoading && (
                                  <>
                                    <div className="d-flex justify-content-center flex-column">
                                      <button
                                        className="btn_fill"
                                        id="btn_sendotp"
                                        onClick={getOTP}
                                      >
                                        OTP
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                              {isLoading && (
                                <div className="text-center">
                                  <h6 className="text_green mb-2">Sending OTP</h6>
                                  <BeatLoader
                                    color={"var(--click-color)"}
                                    loading={true}
                                  />
                                </div>
                              )}
                            </>
                          )}

                          {/* start otp section */}
                          {otpSliderState && (
                            <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                              <label
                                htmlFor=""
                                className="d-flex align-items-center mb-2"
                              >
                                Enter 6 digit OTP
                                <span
                                  onClick={toggleOtpVisibility}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    fontSize: "24px",
                                    userSelect: "none",
                                  }}
                                  className="material-symbols-outlined"
                                >
                                  {isOtpVisible ? "visibility" : "visibility_off"}
                                </span>
                              </label>

                              <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={
                                  <span style={{ margin: "10px 5px 20px 5px" }}>
                                    -
                                  </span>
                                }
                                renderInput={(props) => (
                                  <input
                                    {...props}
                                    type={isOtpVisible ? "text" : "password"}
                                    onWheel={handleWheel}
                                    inputMode="numeric"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid gray",
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      margin: "10px 0px 20px 0px",
                                    }}
                                  />
                                )}
                              />

                              {error && (
                                <Alert variant="danger" className="mt-2">
                                  {error}
                                </Alert>
                              )}

                              {!isLoading && (
                                <div className="d-flex justify-content-center flex-column w-100">
                                  <button className="btn_fill" onClick={verifyOTP}>
                                    Confirm
                                  </button>
                                </div>
                              )}
                              {isLoading && (
                                <div className="text-center">
                                  <h6 className="text_green mb-2">Sign In</h6>
                                  <BeatLoader
                                    color="var(--click-color)"
                                    loading={true}
                                  />
                                </div>
                              )}
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  marginTop: "20px",
                                }}
                              >
                                <Link
                                  to="/privacypolicy"
                                  style={{ color: "var(--click-color)" }}
                                >
                                  <span
                                    class="material-symbols-outlined"
                                    style={{ fontSize: "18px" }}
                                  >
                                    lock
                                  </span>
                                  &nbsp;
                                  {dbOrganizationDocuments &&
                                    dbOrganizationDocuments.short_name}
                                </Link>
                                &nbsp; ensures security with encrypted OTP and secure
                                authentication.
                              </label>
                            </div>
                          )}
                          {/* end otp section */}

                          {/* IF USER IS NEW  */}
                          <div>
                            {newUserSliderState && (
                              <div>
                                <div className="">
                                  {/* START GENDER SELECTION SLIDER */}
                                  <div>
                                    {genderSelectionSliderState && (
                                      <div className="login-gender-selection-slider">
                                        <h5 className="mb-4">Select Your Gender</h5>
                                        <div className="login-gender-options">
                                          <div
                                            className={`login-gender-option ${selectedGender === "male"
                                              ? "login-gender-selected"
                                              : ""
                                              }`}
                                            onClick={() => handleGenderSelect("male")}
                                          >
                                            <img
                                              src="assets/img/men-icon-login.png"
                                              alt="Male"
                                            />
                                            {selectedGender === "male" && (
                                              <div className="login-gender-tick">
                                                &#10003;
                                              </div>
                                            )}
                                            <p>Male</p>
                                          </div>
                                          <div
                                            className={`login-gender-option ${selectedGender === "female"
                                              ? "login-gender-selected"
                                              : ""
                                              }`}
                                            onClick={() =>
                                              handleGenderSelect("female")
                                            }
                                          >
                                            <img
                                              src="assets/img/women-icon-login.png"
                                              alt="Female"
                                            />
                                            {selectedGender === "female" && (
                                              <div className="login-gender-tick">
                                                &#10003;
                                              </div>
                                            )}
                                            <p>Female</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {/* END GENDER SELECTION SLIDER */}

                                  {newUserDetailsSliderState && (
                                    <>
                                      <Box
                                        component="form"
                                        sx={{
                                          "& .MuiTextField-root": {
                                            m: 1,
                                            width: "95%",
                                            textAlign: "center",
                                          },
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          padding: "20px",
                                          borderRadius: "8px",
                                        }}
                                        noValidate
                                        autoComplete="on"
                                      >
                                        <TextField
                                          label={"Full Name"}
                                          id="margin-none"
                                          onChange={(e) => setName(e.target.value)}
                                          value={name}
                                          required
                                        />
                                        <TextField
                                          label={"Email"}
                                          id="margin-none"
                                          onChange={(e) => setEmail(e.target.value)}
                                          value={email}
                                          required
                                        />
                                        <TextField
                                          label={"Current City"}
                                          id="margin-none"
                                          onChange={(e) => setCity(e.target.value)}
                                          value={city}
                                          required
                                        />
                                      </Box>
                                      {error && (
                                        <div className="field_error">{error}</div>
                                      )}
                                      <div className="d-flex justify-content-center mt-2">
                                        <button
                                          className="btn_fill"
                                          onClick={newUserForm}
                                        >
                                          Done
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>)}
                    {/* END CODE FOR LOGIN/SIGNUP VIA PHONE OTP */}


                    {dbAuthTypeDocuments &&
                      dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle" && (
                        <div className="login-pge-horizontal-line-with-or-parent">
                          <div className="login-pge-horizontal-line">
                            <div className="login-pge-horizontal-line-with-or">OR</div>
                          </div>
                        </div>)}

                    {/* START CODE FOR LOGIN/SIGNUP VIA GOOGLE */}
                    {dbAuthTypeDocuments &&
                      (dbAuthTypeDocuments.currentAuth === "Google" ||
                        dbAuthTypeDocuments.currentAuth === "GoogleAndEmailPassword" ||
                        dbAuthTypeDocuments.currentAuth === "PhoneAndGoogle") &&
                      (<div>
                        {mobilenoSliderState && (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <h5 className="mt-4 mb-2">Google</h5>
                            </div>

                            <div className="new_form_field with_icon phoneinput">
                              <div>
                                <button className="signup-login-google-inpt-btn" onClick={signInWithGoogle}><img src="/assets/img/google-icon.png" /><div className="d-flex justify-content-center w-100">Continue with Google</div></button>
                              </div>
                            </div>
                          </>
                        )}

                        {/* IF USER IS NEW  */}
                        {/* <div>
                          {newUserSliderState && (
                            <div>
                              <div className=""> */}
                        {/* START GENDER SELECTION SLIDER */}
                        {/* <div>
                                  {genderSelectionSliderState && (
                                    <div className="login-gender-selection-slider">
                                      <h5 className="mb-4">Select Your Gender</h5>
                                      <div className="login-gender-options">
                                        <div
                                          className={`login-gender-option ${selectedGender === "male"
                                            ? "login-gender-selected"
                                            : ""
                                            }`}
                                          onClick={() => handleGenderSelect("male")}
                                        >
                                          <img
                                            src="assets/img/men-icon-login.png"
                                            alt="Male"
                                          />
                                          {selectedGender === "male" && (
                                            <div className="login-gender-tick">
                                              &#10003;
                                            </div>
                                          )}
                                          <p>Male</p>
                                        </div>
                                        <div
                                          className={`login-gender-option ${selectedGender === "female"
                                            ? "login-gender-selected"
                                            : ""
                                            }`}
                                          onClick={() =>
                                            handleGenderSelect("female")
                                          }
                                        >
                                          <img
                                            src="assets/img/women-icon-login.png"
                                            alt="Female"
                                          />
                                          {selectedGender === "female" && (
                                            <div className="login-gender-tick">
                                              &#10003;
                                            </div>
                                          )}
                                          <p>Female</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div> */}
                        {/* END GENDER SELECTION SLIDER */}

                        {/* {newUserDetailsSliderState && (
                                  <>
                                    <Box
                                      component="form"
                                      sx={{
                                        "& .MuiTextField-root": {
                                          m: 1,
                                          width: "95%",
                                          textAlign: "center",
                                        },
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "20px",
                                        borderRadius: "8px",
                                      }}
                                      noValidate
                                      autoComplete="on"
                                    >
                                      <TextField
                                        label={"Current City"}
                                        id="margin-none"
                                        onChange={(e) => setCity(e.target.value)}
                                        value={city}
                                        required
                                      />
                                    </Box>
                                    {error && (
                                      <div className="field_error">{error}</div>
                                    )}
                                    <div className="d-flex justify-content-center mt-2">
                                      <button
                                        className="btn_fill"
                                        onClick={newUserForm}
                                      >
                                        Done
                                      </button>
                                    </div>
                                  </>
                                )} */}
                        {/* </div>
                            </div>
                          )}
                        </div> */}
                      </div>)}
                    {/* END CODE FOR LOGIN/SIGNUP VIA GOOGLE */}

                    {dbAuthTypeDocuments &&
                      (dbAuthTypeDocuments.currentAuth === "GoogleAndEmailPassword" ||
                        dbAuthTypeDocuments.currentAuth === "PhoneAndEmailPassword") && (
                        <div className="login-pge-horizontal-line-with-or-parent">
                          <div className="login-pge-horizontal-line">
                            <div className="login-pge-horizontal-line-with-or">OR</div>
                          </div>
                        </div>)}

                    {/* START CODE FOR LOGIN/SIGNUP VIA EMAIL PASSWORD */}
                    {dbAuthTypeDocuments &&
                      (dbAuthTypeDocuments.currentAuth === "EmailPassword" ||
                        dbAuthTypeDocuments.currentAuth === "GoogleAndEmailPassword" ||
                        dbAuthTypeDocuments.currentAuth === "PhoneAndEmailPassword") && (
                        <div>
                          {mobilenoSliderState && (
                            <>
                              <div className="d-flex justify-content-center align-items-center">
                                <h5 className="mt-4 mb-2">Email</h5>
                              </div>

                              <div className="new_form_field with_icon phoneinput">
                                <div>
                                  <TextField
                                    label={"Email"}
                                    id="margin-none"
                                    value={email}
                                    required
                                    style={{ minWidth: "100%", marginBottom: "15px" }}
                                  />
                                  <br />
                                  <TextField
                                    label={"Password"}
                                    id="margin-none"
                                    value={name}
                                    required
                                    style={{ minWidth: "100%", marginBottom: "15px" }}
                                  />
                                  {error && (
                                    <Alert variant="danger" className="mt-2">
                                      {error}
                                    </Alert>
                                  )}
                                </div>
                              </div>

                              <div className="">
                                {!isLoading && (
                                  <>
                                    <div className="d-flex justify-content-center flex-column">
                                      <button
                                        className="btn_fill"
                                        id="btn_sendotp"
                                        onClick={getOTP}
                                      >
                                        Confirm
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                              {isLoading && (
                                <div className="text-center">
                                  <h6 className="text_green mb-2">Submitting</h6>
                                  <BeatLoader
                                    color={"var(--click-color)"}
                                    loading={true}
                                  />
                                </div>
                              )}
                            </>
                          )}

                          {/* IF USER IS NEW  */}
                          <div>
                            {newUserSliderState && (
                              <div>
                                <div className="">
                                  {/* START GENDER SELECTION SLIDER */}
                                  <div>
                                    {genderSelectionSliderState && (
                                      <div className="login-gender-selection-slider">
                                        <h5 className="mb-4">Select Your Gender</h5>
                                        <div className="login-gender-options">
                                          <div
                                            className={`login-gender-option ${selectedGender === "male"
                                              ? "login-gender-selected"
                                              : ""
                                              }`}
                                            onClick={() => handleGenderSelect("male")}
                                          >
                                            <img
                                              src="assets/img/men-icon-login.png"
                                              alt="Male"
                                            />
                                            {selectedGender === "male" && (
                                              <div className="login-gender-tick">
                                                &#10003;
                                              </div>
                                            )}
                                            <p>Male</p>
                                          </div>
                                          <div
                                            className={`login-gender-option ${selectedGender === "female"
                                              ? "login-gender-selected"
                                              : ""
                                              }`}
                                            onClick={() =>
                                              handleGenderSelect("female")
                                            }
                                          >
                                            <img
                                              src="assets/img/women-icon-login.png"
                                              alt="Female"
                                            />
                                            {selectedGender === "female" && (
                                              <div className="login-gender-tick">
                                                &#10003;
                                              </div>
                                            )}
                                            <p>Female</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {/* END GENDER SELECTION SLIDER */}

                                  {newUserDetailsSliderState && (
                                    <>
                                      <Box
                                        component="form"
                                        sx={{
                                          "& .MuiTextField-root": {
                                            m: 1,
                                            width: "95%",
                                            textAlign: "center",
                                          },
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          padding: "20px",
                                          borderRadius: "8px",
                                        }}
                                        noValidate
                                        autoComplete="on"
                                      >
                                        <TextField
                                          label={"Full Name"}
                                          id="margin-none"
                                          onChange={(e) => setName(e.target.value)}
                                          value={name}
                                          required
                                        />
                                        <TextField
                                          label={"Email"}
                                          id="margin-none"
                                          onChange={(e) => setEmail(e.target.value)}
                                          value={email}
                                          required
                                        />
                                        <TextField
                                          label={"Current City"}
                                          id="margin-none"
                                          onChange={(e) => setCity(e.target.value)}
                                          value={city}
                                          required
                                        />
                                      </Box>
                                      {error && (
                                        <div className="field_error">{error}</div>
                                      )}
                                      <div className="d-flex justify-content-center mt-2">
                                        <button
                                          className="btn_fill"
                                          onClick={newUserForm}
                                        >
                                          Done
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>)}
                    {/* END CODE FOR LOGIN/SIGNUP VIA EMAIL PASSWORD */}

                    <div className="d-flex align-items-center mt-2">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      &nbsp;
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        By proceeding, I agree to&nbsp;
                        {dbOrganizationDocuments &&
                          dbOrganizationDocuments.short_name}
                        &nbsp;
                        <Link
                          to="/term-condition"
                          style={{ color: "var(--click-color)" }}
                        >
                          T&C
                        </Link>
                        &nbsp;&&nbsp;
                        <Link
                          to="/privacypolicy"
                          style={{ color: "var(--click-color)" }}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
