import React from "react";
import { Route, Routes } from "react-router-dom";
import "../happyerpCss/HappyerpIndex.scss";
import "../happyerpCss/HappyerpIndex.css";

// import pages
// import BusinessOneHeader from "../businessOneComponents/BusinessOneHeader";
import HappyerpHome from "../happyerpPages/happyerpStaticPages/happyerpHome/HappyerpHome";
import GlobalRoute from "../../../global route/GlobalRoute";
import Navbar from "../../../default components/Navbar";
import ColorsComponent from "../../../default components/ColorsComponent";
import PGBusinessThreeProfile from "../../business/businessThree/businessThreePages/PGBusinessThreeProfile";
import HappyerpDashboard from "../happyerpPages/happyerpDynamicPages/happyerpAdmin/HappyerpDashBoard";

const happyerpRoute = () => {
  return (
    <div className="happyerp">
      {/* <BusinessOneHeader /> */} 
      <Navbar />
      <Routes>
        <Route path="/" element={<HappyerpHome />}></Route>
        <Route path="/dashboard" element={<HappyerpDashboard />}></Route>
        <Route path="/profile" element={<PGBusinessThreeProfile />} />
      </Routes>
      <GlobalRoute />
      <ColorsComponent />
      {/* <Footer /> */}
    </div>
  );
};

export default happyerpRoute;
